import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import Solution from "../../../../components/Solution/Solution";

type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const Pilot = ({ userAgent, lang }: Props) => {
  return (
    <main className="Pilot">
      <Solution
        lang={lang}
        gradationType="pilotbasic"
        box_titles={box_titles}
        gradation={true}
        type="pilot"
        box_layouts={box_layouts}
        userAgent={userAgent}
        src="/assets/solution/dron/pilot/pc/pilot-img.png"
        srcSet="/assets/solution/dron/pilot/pc/pilot-img@2x.png 2x, /assets/solution/dron/pilot/pc/pilot-img@3x.png 3x"
      />
    </main>
  );
};

export default Pilot;

const box_layouts = [
  {
    point: "Point 01",
    title: <>다양한 미션수행 가능</>,
    engtitle: <>Various Mission Types</>,

    engsub: (
      <>
        <div className="dot"></div>
        <div>Way Point Mission</div>
      </>
    ),

    sub: (
      <>
        <div className="dot"></div>
        <div className="div"> Way Point Mission</div>
      </>
    ),
    engsub1: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Mission</div>
      </>
    ),
    sub1: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Mission</div>
      </>
    ),
    engsub2: (
      <>
        <div className="dot"></div>
        <div className="div">Grid/Polygon Interval Mission</div>
      </>
    ),

    sub2: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Interval Mission</div>
      </>
    ),

    sub3: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Distance Interval Mission</div>
      </>
    ),

    engsub3: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Distance Interval Mission</div>
      </>
    ),

    src: "/assets/solution/dron/pilot/pc/pilot-boximg1.png",
    srcSet:
      "/assets/solution/dron/pilot/pc/pilot-boximg1@2x.png 2x, /assets/solution/dron/pilot/pc/pilot-boximg1@3x.png 3x",
  },

  {
    point: "Point 02",
    title: <>드론정보 관제시스템 송출</>,
    engtitle: <>Sharing Drone Info with Control System</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>드론 GPS정보(경위도 좌표)</div>
      </>
    ),

    sub1: (
      <>
        <div className="dot"></div>
        <>배터리 정보</>
      </>
    ),
    sub2: (
      <>
        <div className="dot"></div>
        <>드론 상태 (고도, 높이, 속도, 거리 등)</>
      </>
    ),

    sub3: (
      <>
        <div className="dot"></div>
        <>실시간 동영상 스트리밍, Live Map</>
      </>
    ),

    sub4: (
      <>
        <div className="dot"></div>
        <>실시간 미션 정보</>
      </>
    ),

    engsub: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          Drone GPS info <br className="mb" />
          (longitude and latitude coordinates)
        </div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <>Battery Info</>
      </>
    ),

    engsub2: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          Drone status info <br className="mb" />
          (altitude, speed, distance, etc.)
        </div>
      </>
    ),
    engsub3: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">Real-time video streaming, Live Map</div>
      </>
    ),

    engsub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">Real-time mission info</div>
      </>
    ),

    src: "/assets/solution/dron/pilot/pc/pilot-boximg2.png",
    srcSet:
      "/assets/solution/dron/pilot/pc/pilot-boximg2@2x.png 2x, /assets/solution/dron/pilot/pc/pilot-boximg2@3x.png 3x",
    type: "reverse",
  },

  {
    point: "Point 03",
    title: <>카메라 촬영</>,
    engtitle: <>Camera Shooting</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>자동 촬영 모드</div>
      </>
    ),

    sub1: (
      <>
        <div className="dot"></div>
        <div>수동 촬영 모드</div>
      </>
    ),

    sub2: (
      <>
        <div className="dot"></div>
        <div>전문가 촬영 모드</div>
      </>
    ),

    engsub: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">Auto shooting mode</div>
      </>
    ),
    engsub1: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">Manual shooting mode</div>
      </>
    ),

    engsub2: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">Expert shooting mode</div>
      </>
    ),

    src: "/assets/solution/dron/pilot/pc/pilot-boximg3.png",
    srcSet:
      "/assets/solution/dron/pilot/pc/pilot-boximg3@2x.png 2x, /assets/solution/dron/pilot/pc/pilot-boximg3@3x.png 3x",
  },

  {
    point: "Point 04",
    title: <>기체 설정</>,
    engtitle: <>Aircraft Configuration</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>Photo/Video Setting</div>
      </>
    ),
    engsub: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Photo/Video Setting</div>
      </>
    ),
    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Mc Setting</div>
      </>
    ),

    engsub1: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Mc Setting</div>
      </>
    ),
    sub2: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Visual Navigation Setting</div>
      </>
    ),

    engsub2: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Visual Navigation Setting</div>
      </>
    ),
    sub3: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Remote Controller/HD Setting</div>
      </>
    ),

    engsub3: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Remote Controller/HD Setting</div>
      </>
    ),
    sub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Battery/Gimbal Setting</div>
      </>
    ),
    engsub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Battery/Gimbal Setting</div>
      </>
    ),

    sub5: (
      <>
        {" "}
        <div className="dot"></div>
        <div>General Setting</div>
      </>
    ),
    engsub5: (
      <>
        {" "}
        <div className="dot"></div>
        <div>General Setting</div>
      </>
    ),

    sub6: (
      <>
        {" "}
        <div className="dot"></div>
        <div>RTK Setting</div>
      </>
    ),
    engsub6: (
      <>
        {" "}
        <div className="dot"></div>
        <div>RTK Setting</div>
      </>
    ),
    src: "/assets/solution/dron/pilot/pc/pilot-boximg4.png",
    srcSet:
      "/assets/solution/dron/pilot/pc/pilot-boximg4@2x.png 2x, /assets/solution/dron/pilot/pc/pilot-boximg4@3x.png 3x",
    type: "reverse",
  },
];

const box_titles = [
  {
    engtitle: (
      <>
        DFOS
        <br className="pc" /> Pilot(App)
      </>
    ),
    title: (
      <>
        DFOS
        <br className="pc" /> Pilot(App)
      </>
    ),
    engsub: (
      <>
        An app that allows users to control
        <br /> drones conveniently for shooting
        <br /> photographs and flights
      </>
    ),
    sub: (
      <>
        드론의 사진, 동영상, 촬영 및 경로 비행에 대한 <br />
        사용자가 편리하게 드론 컨트롤을 할 수 있는 APP
      </>
    ),
  },
];
