import React from "react";
import { UserAgentType } from "../../../../common/common.inerface";
import "./css/index.css";
type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const AwardDeed = ({ userAgent, lang }: Props) => {
  return (
    <main className="AwardDeed">
      <div className="main-section">
        <div className="gradation-section"></div>
        <div className="title-section">
          <div className="title">
            {lang === "kor" ? (
              <>
                {" "}
                IGIS는 믿을 수 있는 <br />
                전문 인증 기업입니다{" "}
              </>
            ) : (
              <>
                {" "}
                "We are a trusted <br />
                professional company"
              </>
            )}
          </div>
        </div>

        <div className="img-section">
          {lang === "kor" ? (
            <>
              {" "}
              {AwardImg.map((item, index) => {
                return (
                  <div key={index} className="content">
                    <div className="deed-img">
                      <img src={item.src} alt="img" srcSet={item.srcset} />
                    </div>

                    <div className="title">{item.title}</div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {" "}
              {AwardEng.map((item, index) => {
                return (
                  <div key={index} className="content">
                    <div className="deed-img">
                      <img src={item.src} alt="img" srcSet={item.srcset} />
                    </div>

                    <div className="title">{item.engtitle}</div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default AwardDeed;

const AwardImg = [
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed1.png",
    title: (
      <>
        청년친화 <br className="mb" />
        강소기업 선정서
      </>
    ),
    engtitle: <>Youth-Friendly Small and Medium-Sized Enterprise</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed1@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed1@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed2.png",
    title: "혁신제품 지정 인증서",
    engtitle: (
      <>
        Designated as One of the National <br />
        Representative of 1000 Innovative Companies
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed2@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed2@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed3.png",
    title: <>혁신기업국가대표1000</>,
    engtitle: (
      <>
        Certificate of Innovative <br />
        Product Designation
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed3@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed3@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed4.png",
    title: (
      <>
        기술평가 우수기업 <br className="mb" />
        인증서
      </>
    ),
    engtitle: (
      <>
        Excellent Technology Evaluation
        <br /> Enterprise Certificate
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed4@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed4@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed5.png",
    title: (
      <>
        조달청 벤처나라 <br className="mb" />
        지정증서
      </>
    ),
    engtitle: (
      <>
        Certificate of Designation as a 'Venture Nara' Vendor
        <br /> by Public Procurement Service
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed5@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed5@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed6.png",
    title: "Inno-Biz 확인서",
    engtitle: <>Inno-Biz Confirmation</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed6@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed6@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed7.png",
    title: "G-PASS 기업 지정서",
    engtitle: <>GPASS Designation</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed7@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed7@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed8.png",
    title: <>ISO9001</>,
    engtitle: <>ISO9001</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed8@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed8@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed9.png",
    title: <>Pre-스타기업 지정서</>,
    engtitle: <>Pre-Star Enterprise Certificate</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed9@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed9@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed10.png",
    title: (
      <>
        기업부설연구소
        <br className="mb" /> 인정서
      </>
    ),
    engtitle: <>Certificate of Establishment of R&D Center</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed10@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed1@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awarddeed/pc/deed11.png",
    title: "벤처기업 확인서",
    engtitle: <>Venture Business Certificate</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed11@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed12@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awarddeed/pc/deed12.png",
    title: "Main-Biz 확인서",
    engtitle: <>Main-BIZ Certificate</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed12@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed12@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awarddeed/pc/deed13.png",
    title: (
      <>
        사업재편계획 <br className="mb" /> 승인증서
      </>
    ),
    engtitle: (
      <>
        Certificate of Government Approval
        <br /> for Business Restructuring Plans
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed13@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed13@3x.png 3x",
  },
];

const AwardEng = [
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed1.png",
    title: (
      <>
        청년친화 <br className="mb" />
        강소기업 선정서
      </>
    ),
    engtitle: <>Youth-Friendly Small and Medium-Sized Enterprise</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed1@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed1@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed2.png",
    title: "혁신제품 지정 인증서",
    engtitle: <>Certificate of Innovative Product Designation</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed2@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed2@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed3.png",
    title: <>혁신기업국가대표1000</>,
    engtitle: (
      <>
        Innovative Enterprise National
        <br /> Representative 1000
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed3@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed3@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed4.png",
    title: (
      <>
        기술평가 우수기업 <br className="mb" />
        인증서
      </>
    ),
    engtitle: (
      <>
        Excellent technology evaluation
        <br />
        company certificate
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed4@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed4@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed5.png",
    title: (
      <>
        조달청 벤처나라 <br className="mb" />
        지정증서
      </>
    ),
    engtitle: (
      <>
        Public Procurement <br />
        Service venture country designation certificate
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed5@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed5@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed6-eng.png",
    title: "Inno-Biz 확인서",
    engtitle: <>Inno-Biz Confirmation</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed6-eng@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed6-eng@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed7-eng.png",
    title: "G-PASS 기업 지정서",
    engtitle: <>G-PASS company designation</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed7-eng@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed7-eng@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed8-eng.png",
    title: <>ISO9001</>,
    engtitle: <>ISO9001</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed8-eng@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed8-eng@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed9.png",
    title: <>Pre-스타기업 지정서</>,
    engtitle: <>Pre-star company designation</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed9@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed9@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awarddeed/pc/deed10.png",
    title: (
      <>
        기업부설연구소
        <br className="mb" /> 인정서
      </>
    ),
    engtitle: (
      <>
        Company-affiliated research <br />
        institute certificate
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed10@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed1@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awarddeed/pc/deed11.png",
    title: "벤처기업 확인서",
    engtitle: <>Venture company confirmation</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed11@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed12@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awarddeed/pc/deed12.png",
    title: "Main-Biz 확인서",
    engtitle: <>Main-Biz Confirmation</>,
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed12@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed12@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awarddeed/pc/deed13.png",
    title: (
      <>
        사업재편계획 <br className="mb" /> 승인증서
      </>
    ),
    engtitle: (
      <>
        Business reorganization <br />
        plan approval certificate
      </>
    ),
    srcset:
      "/assets/company/companyaward/awarddeed/pc/deed13@2x.png 2x, /assets/company/companyaward/awarddeed/pc/deed13@3x.png 3x",
  },
];
