import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import Solution from "../../../../components/Solution/Solution";

type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const Panorama = ({ userAgent, lang }: Props) => {
  return (
    <main className="Panorama">
      <Solution
        lang={lang}
        gradationType="circle"
        box_titles={box_titles}
        gradation={true}
        type="panorama"
        box_layouts={box_layouts}
        userAgent={userAgent}
        src="/assets/solution/dron/panorama/pc/panorama-img.png"
        srcSet="/assets/solution/dron/panorama/pc/panorama-img@2x.png 2x, /assets/solution/dron/panorama/pc/panorama-img@3x.png 3x"
      />
    </main>
  );
};

export default Panorama;

const box_layouts = [
  {
    point: "Point 01",
    title: <>혁신적인 파노라마 영상 활용 시스템</>,
    engtitle: <>Innovative Panorama Image Utilization System</>,
    sub: (
      <>
        <div className="dot"></div>
        <div>드론 촬영 사진에 대한 360º 파노라마 사진 제작</div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>360-degree drone panorama image production</div>
      </>
    ),

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">편리한 자동 파노라마 사진 변환 지원</div>
      </>
    ),

    engsub1: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          upport convenient automated panoramic image creation
        </div>
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>자동 파노라마 사진 실시간 제작 현황 확인
      </>
    ),
    engsub2: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          {" "}
          Real-time checking of automated panoramic image <br />
          creation status
        </div>
      </>
    ),

    src: "/assets/solution/dron/panorama/pc/panorama-boximg1.png",
    srcSet:
      "/assets/solution/dron/panorama/pc/panorama-boximg1@2x.png 2x, /assets/solution/dron/panorama/pc/panorama-boximg1@3x.png 3x",
  },

  {
    point: "Point 02",
    title: <>편리한 파노라마 영상 제작과 관리</>,
    engtitle: <>Convenient Panoramic Image Production and Management</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>현장 관리를 위한 편집 및 공간자료 편집 기능</div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>Editing of panoramic and geospatial data for field management</div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div>
          {" "}
          Check drone shooting location through symbols <br />
          marked on the map
        </div>
      </>
    ),

    engsub2: (
      <>
        <div className="dot"></div>
        <div>Cadastral maps layer On/Off</div>
      </>
    ),

    engsub3: (
      <>
        <div className="dot"></div>
        <div>Fast and easy check by web and mobile service</div>
      </>
    ),

    engsub4: (
      <>
        <div className="dot"></div>
        <div>
          Possible to produce, store, manage, and distribute panoramic content
        </div>
      </>
    ),

    sub1: (
      <>
        <div className="dot"></div>
        <div>심볼을 통한 촬영 위치 확인 등의 편의 기능 지원</div>
      </>
    ),

    sub2: (
      <>
        <div className="dot"></div>
        <div>지적도 레이어 On/Off</div>
      </>
    ),

    sub3: (
      <>
        <div className="dot"></div>
        <div>
          웹과 모바일 서비스 연동으로 빠르고 <br className="mb" /> 쉬운 콘텐츠
          확인
        </div>
      </>
    ),

    sub4: (
      <>
        <div className="dot"></div>
        <div>파노라마 콘텐츠의 생산, 저장, 관리 및 배포 지원</div>
      </>
    ),

    src: "/assets/solution/dron/panorama/pc/panorama-boximg2.png",
    srcSet:
      "/assets/solution/dron/panorama/pc/panorama-boximg2@2x.png 2x, /assets/solution/dron/panorama/pc/panorama-boximg2@3x.png 3x",
    type: "reverse",
  },

  {
    point: "Point 03",
    title: <>다양한 디바이스 지원</>,
    engtitle: <>Various Devices Supported</>,
    engsub: (
      <>
        <div className="dot"></div>
        <div className="div">
          Virtual experience of tourist attractions and landmarks <br />
          utilizing panoramic images
        </div>
      </>
    ),

    sub: (
      <>
        <div className="dot"></div>
        <div>
          파노라마 사진을 활용한 관광지 및 명소 <br className="mb" /> 가상체험
          가능
        </div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div>Field check with panoramic images</div>
      </>
    ),

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        파노라마 사진을 활용한 현장 확인 가능
      </>
    ),

    src: "/assets/solution/dron/panorama/pc/panorama-boximg3.png",
    srcSet:
      "/assets/solution/dron/panorama/pc/panorama-boximg3@2x.png 2x, /assets/solution/dron/panorama/pc/panorama-boximg3@3x.png 3x",
  },
];

const box_titles = [
  {
    title: (
      <>
        DFOS
        <br className="" />
        Panorama Platform
      </>
    ),

    engtitle: (
      <>
        DFOS
        <br className="" />
        Panorama Platform
      </>
    ),
    sub: (
      <>
        다양한 드론과 카메라를 활용한 사진 촬영, 파노라마 변환{" "}
        <br className="pc" /> <br className="tablet" />및 평면공간자료(지적도
        등)도 함께 확인 가능한 파노라마 웹 서비스
      </>
    ),

    engsub: (
      <>
        A web service converting the photos taken by <br className="pc" />{" "}
        <br className="tablet" />
        multiple drones & cameras into a 360-degree
        <br className="pc" /> <br className="tablet" /> panorama to be viewed
        with geospatial data <br className="pc" />
        (cadastral maps, etc.)
      </>
    ),
  },
];
