import React from "react";
import {
  NewsInterface,
  UserAgentType,
} from "../../../../common/common.inerface";
import "./css/index.css";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { formatDate } from "../../../../lib/lib";
type Props = {
  userAgent: UserAgentType;
  data: NewsInterface[];
};
const CompanyVideo = ({ userAgent, data }: Props) => {
  const navigate = useNavigate();
  const __nav = useCallback(
    (id: any) => {
      navigate(`/news/video/detail/${id}`);
    },

    [data, navigate]
  );
  return (
    <main className="CompanyVideo">
      <div className="main-section">
        {data.map((item, idx) => {
          return (
            <button key={idx} className="box" onClick={() => __nav(item.id)}>
              <div
                className="thumbnail"
                style={{
                  backgroundImage: `url(${item.img})`,
                }}
              ></div>

              <div className="title-wrapper">
                <div className="title">{item.title}</div>
                <div className="date">{formatDate(item.timestamp, "-")}</div>
              </div>
            </button>
          );
        })}
      </div>
    </main>
  );
};

export default CompanyVideo;
