export const ContactInitial = () => {
  return {
    manager: "",
    company: "",
    phone: "",
    email: "",
    rank: "",
    isAgree: false,
    selectedButton: 0,
    selectedProductButton: 0,
    use: "",
    text: "",
    fileData: [],
  };
};
