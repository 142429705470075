import { Route, Routes } from "react-router-dom";
import TopNavSection from "../../components/TopNavSection/TopNavSection";
import { UserAgentType } from "../../common/common.inerface";

import "./css/index.css";
import BusinessGIS from "../../page/Business/BusinessGIS/BusinessGIS";
import BusinessDron from "../../page/Business/BusinessDron/BusinessDron";
import BusinessService from "../../page/Business/BusinessService/BusinessService";
import BusinessFMS from "../../page/Business/BusinessFMS/BusinessFMS";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";

type Props = {
  userAgent: UserAgentType;
};

const BusinessRoutes = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  return (
    <main className="business-page">
      <div className="title-section">
        <div className="left">
          <div className="title">BUSINESS</div>
          {lang === "kor" && <div className="subtitle">사업분야</div>}
        </div>

        <div className="right">
          <div className="gif">
            <video autoPlay loop muted playsInline preload="auto">
              <source src="/video/business.webm" type="video/webm" />
              <source src="/video/business.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <TopNavSection
        nav_layouts={nav_layouts}
        page_type="business"
        userAgent={userAgent}
        lang={lang}
      />
      <Routes>
        <Route
          path="/"
          element={<BusinessGIS userAgent={userAgent} lang={lang} />}
        />
        <Route
          path="dron"
          element={<BusinessDron userAgent={userAgent} lang={lang} />}
        />
        <Route
          path="service"
          element={<BusinessService userAgent={userAgent} lang={lang} />}
        />
        <Route
          path="fms"
          element={<BusinessFMS userAgent={userAgent} lang={lang} />}
        />
      </Routes>
    </main>
  );
};

export default BusinessRoutes;

const nav_layouts = [
  {
    title: "GIS 시스템",
    eng: "GIS",
    link: "/business",
  },
  {
    title: "드론",
    eng: "Drone",
    link: "/business/dron",
  },

  {
    title: "FMS",
    eng: "FMS",
    link: "/business/fms",
  },

  {
    title: "R&D 및 용역",
    eng: "R&D & Services",
    link: "/business/service",
  },
];
