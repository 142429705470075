import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import NewsCard from "../../../../components/NewsCard/NewsCard";

type Props = {
  userAgent: UserAgentType;
  data: any;
};
const CompanyNews = ({ userAgent, data }: Props) => {
  const navigate = useNavigate();

  const __nav = useCallback(
    (id: any) => {
      navigate(`/news/detail/${id}`);
    },

    [data, navigate]
  );
  return (
    <main className="CompanyNews">
      <div className="main-section">
        <div className="news-wrapper">
          {data.map((item: any, idx: any) => {
            return <NewsCard data={item} idx={idx} __nav={__nav} key={idx} />;
          })}
        </div>
      </div>
    </main>
  );
};

export default CompanyNews;
