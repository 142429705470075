import React, { useCallback, useState } from "react";
import "./css/index.css";

type Props = {
  title: string;
  data: any;
  type: "contact" | "product";
  Update: (type: string, value: string | number) => void;
  lang: "kor" | "eng";
};

const ContactButton = ({ title, data, Update, type, lang }: Props) => {
  const handleButtonClick = useCallback(
    (idx: number) => {
      if (type === "contact") {
        Update("selectedButton", idx);
      } else {
        Update("selectedProductButton", idx);
      }
    },
    [Update, type]
  );

  return (
    <section className="contact-button">
      <div className="title">{title}</div>

      {type === "contact" ? (
        <div className="btn-wrapper">
          {contact.map(({ content, eng }, idx) => (
            <button
              key={idx}
              className="contact-btn"
              onClick={() => handleButtonClick(idx)}
              style={{
                backgroundColor: data === idx ? "white" : "#242627",
                color: data === idx ? "black" : "white",
              }}
            >
              <div className="select">
                {data === idx && (
                  <>
                    <img src="/assets/common/check.svg" alt="" />
                  </>
                )}
              </div>
              {lang === "kor" ? content : eng}
            </button>
          ))}
        </div>
      ) : (
        <div className="btn-wrapper">
          {product.map(({ content, eng }, idx) => (
            <button
              key={idx}
              className="contact-btn"
              onClick={() => handleButtonClick(idx)}
              style={{
                backgroundColor: data === idx ? "white" : "#242627",
                color: data === idx ? "black" : "white",
              }}
            >
              <div className="select">
                {data === idx && (
                  <>
                    <img src="/assets/common/check.svg" alt="" />
                  </>
                )}
              </div>
              {lang === "eng" && eng ? eng : content}
            </button>
          ))}
        </div>
      )}
    </section>
  );
};

export default ContactButton;

const contact = [
  {
    content: "제품문의",
    eng: "Product inquiry",
  },
  {
    content: "라이선스 문의",
    eng: "License inquiry",
  },
  {
    content: "기타 문의",
    eng: "Other inquiries",
  },
];
const product = [
  {
    content: "GIS Solution",
  },
  {
    content: "Drone Solution",
  },
  {
    content: "기타",
    eng: "Other",
  },
];
