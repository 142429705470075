import React from "react";
import { UserAgentType } from "../../../../common/common.inerface";
import "./css/index.css";
type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const AwardProperty = ({ userAgent, lang }: Props) => {
  return (
    <main className="AwardProperty">
      <div className="main-section">
        <div className="gradation-section"></div>
        <div className="title-section">
          <div className="title">
            {lang === "kor" ? (
              <>
                {" "}
                IGIS는 자체 연구개발을 통한 <br />
                다수의 특허가 있습니다
              </>
            ) : (
              <>
                Our IP portfolio attained through
                <br className="pc" /> diligent research and development
                <br className="pc" /> endeavors
              </>
            )}
          </div>
        </div>

        <div className="img-section">
          {AwardImg.map((item, index) => {
            return (
              <div key={index} className="content">
                <div className="property-img">
                  <img src={item.src} alt="img" srcSet={item.srcset} />
                </div>

                {lang === "kor" ? item.title : item.engtitle}
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default AwardProperty;

const AwardImg = [
  {
    src: "/assets/company/companyaward/awardproperty/pc/property1.png",
    title: (
      <>
        특허 등록 120613
        <br /> U-의료센터를 이용한 <br className="tablet" />{" "}
        원격의료시스템
      </>
    ),
    engtitle: "Remote medical system using U-medical center",
    srcset:
      "/assets/company/companyaward/awardproperty/pc/property1@2x.png 2x, /assets/company/companyaward/awardproperty/pc/property1@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardproperty/pc/property2.png",
    title: (
      <>
        특허 등록 170626 <br />
        지리정보시스템을 이용한 공동 프로젝트
        <br className="pc" />
        운영방법 <br className="tablet" />
        10-1752781 IGIS
      </>
    ),

    engtitle:
      "Joint project operation method using geographic information system",
    srcset:
      "/assets/company/companyaward/awardproperty/pc/property2@2x.png 2x, /assets/company/companyaward/awardproperty/pc/property2@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardproperty/pc/property3.png",
    title: (
      <>
        특허 등록 190830 <br />
        지리정보시스템을 <br className="tablet" /> 이용한
        현장관리시스템 <br />
        및 방법 10-2018844 IGIS
      </>
    ),
    engtitle:
      "Field management systems and methods using geographic information systems",
    srcset:
      "/assets/company/companyaward/awardproperty/pc/property3@2x.png 2x, /assets/company/companyaward/awardproperty/pc/property3@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardproperty/pc/property4.png",
    title: (
      <>
        특허 등록 200401 <br />
        무인항공기를 이용한 <br className="mb" />{" "}
        산림병해충 <br />
        의심목 선별시스템 <br className="tablet" />
        10-2098259 DFOS
      </>
    ),
    engtitle: "Forest pest screening system using unmanned aerial vehicles",
    srcset:
      "/assets/company/companyaward/awardproperty/pc/property4@2x.png 2x, /assets/company/companyaward/awardproperty/pc/property4@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardproperty/pc/property5.png",
    title: (
      <>
        특허 등록 210105 <br />
        드론기체세팅 및 자동운항을 통한 <br />
        관제시스템 및 방법 10-2201056 DFOS
      </>
    ),
    engtitle:
      "Drone airframe setting and automatic operation control system and method",
    srcset:
      "/assets/company/companyaward/awardproperty/pc/property5@2x.png 2x, /assets/company/companyaward/awardproperty/pc/property5@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardproperty/pc/property6.png",
    title: (
      <>
        특허 등록 210201 <br />
        스마트 드론 관제시스템 <br />
        10-2213036 DFOS
      </>
    ),
    engtitle: "Smart drone control system",
    srcset:
      "/assets/company/companyaward/awardproperty/pc/property6@2x.png 2x, /assets/company/companyaward/awardproperty/pc/property6@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardproperty/pc/property7.png",
    title: (
      <>
        특허 등록 220103 <br />
        파노라마 영상 제공시스템 <br />
        10-2347972 DFOS Panorama
      </>
    ),
    engtitle: "Panoramic image generation system",
    srcset:
      "/assets/company/companyaward/awardproperty/pc/property7@2x.png 2x, /assets/company/companyaward/awardproperty/pc/property7@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardproperty/pc/property8.png",
    title: (
      <>
        특허 등록 220915 <br />
        드론 영상 관리 시스템 <br />
        10-2445296 DFOS
      </>
    ),
    engtitle: "Drone image management system",
    srcset:
      "/assets/company/companyaward/awardproperty/pc/property8@2x.png 2x, /assets/company/companyaward/awardproperty/pc/property8@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardproperty/pc/property9.png",
    title: (
      <>
        상표 등록 200904 <br />
        dfos 40-1640330 DFOS
      </>
    ),
    engtitle: "Trademark registration: DFOS",
    srcset:
      "/assets/company/companyaward/awardproperty/pc/property9@2x.png 2x, /assets/company/companyaward/awardproperty/pc/property9@3x.png 3x",
  },
];
