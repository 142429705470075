import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import Solution from "../../../../components/Solution/Solution";

type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const Viewer = ({ userAgent, lang }: Props) => {
  return (
    <main className="viewer">
      <Solution
        lang={lang}
        gradationType="viewercircle"
        box_titles={box_titles}
        gradation={true}
        type="viewer"
        box_layouts={box_layouts}
        userAgent={userAgent}
        src="/assets/solution/dron/viewer/pc/viewer-img.png"
        srcSet="/assets/solution/dron/viewer/pc/viewer-img@2x.png 2x, /assets/solution/dron/viewer/pc/viewer-img@3x.png 3x"
      />
    </main>
  );
};

export default Viewer;

const box_layouts = [
  {
    point: "Point 01",
    title: <>다양한 미션 모니터링 및 배경지도</>,
    engtitle: <>Mission Monitoring & Various Background Maps</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>Way Point Mission 모니터링</div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>Way Point Mission</div>
      </>
    ),
    engsub1: (
      <>
        <div className="dot"></div>
        <div>Grid/Polygon Mission</div>
      </>
    ),
    engsub2: (
      <>
        <div className="dot"></div>
        <div>Grid/Polygon Interval Mission</div>
      </>
    ),
    engsub3: (
      <>
        <div className="dot"></div>
        <div>Grid/Polygon Distance Interval</div>
      </>
    ),

    engsub4: (
      <>
        <div className="dot"></div>
        <div>
          {" "}
          Various background maps supported
          <br />
          including Google Maps
        </div>
      </>
    ),

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        Grid/Polygon Mission 모니터링
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>Grid/Polygon Interval Mission 모니터링
      </>
    ),

    sub3: (
      <>
        {" "}
        <div className="dot"></div>Grid/Polygon Distance Interval Mission{" "}
        <br className="mb" />
        모니터링
      </>
    ),

    sub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Google, Naver 등</div>
      </>
    ),

    src: "/assets/solution/dron/viewer/pc/viewer-boximg1.png",
    srcSet:
      "/assets/solution/dron/viewer/pc/viewer-boximg1@2x.png 2x, /assets/solution/dron/viewer/pc/viewer-boximg1@3x.png 3x",
  },

  {
    point: "Point 02",
    title: <>드론 정보 모니터링</>,
    engtitle: <>Drone Info Monitoring</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>드론 GPS정보(경위도 좌표)</div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>Drone GPS info (longitude and latitude coordinates)</div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div>Battery status</div>
      </>
    ),

    engsub2: (
      <>
        <div className="dot"></div>
        <div>Finish Action info</div>
      </>
    ),

    engsub3: (
      <>
        <div className="dot"></div>
        <div>Point Action info</div>
      </>
    ),

    engsub4: (
      <>
        <div className="dot"></div>
        <div>
          {" "}
          Forward overlap & lateral
          <br /> overlap info
        </div>
      </>
    ),

    engsub5: (
      <>
        <div className="dot"></div>
        <div>Laps of mission</div>
      </>
    ),

    engsub6: (
      <>
        <div className="dot"></div>
        <div>DGimbal angle</div>
      </>
    ),

    sub1: (
      <>
        <div className="dot"></div>
        <div>드론 상태 (고도, 높이, 속도, 거리 등) 모니터링</div>
      </>
    ),

    sub2: (
      <>
        <div className="dot"></div>
        <div>배터리상태 모니터링</div>
      </>
    ),

    sub3: (
      <>
        <div className="dot"></div>
        <div>Finish Action 정보</div>
      </>
    ),

    sub4: (
      <>
        <div className="dot"></div>
        <div>Point Action 정보</div>
      </>
    ),

    sub5: (
      <>
        <div className="dot"></div>
        <div>종중복도, 횡중복도 정보</div>
      </>
    ),

    sub6: (
      <>
        <div className="dot"></div>
        <div>미션 반복 횟수</div>
      </>
    ),

    sub7: (
      <>
        <div className="dot"></div>
        <div>짐벌 각도 정보</div>
      </>
    ),

    src: "/assets/solution/dron/viewer/pc/viewer-boximg1.png",
    srcSet:
      "/assets/solution/dron/viewer/pc/viewer-boximg2@2x.png 2x, /assets/solution/dron/viewer/pc/viewer-boximg2@3x.png 3x",
    type: "reverse",
  },
];

const box_titles = [
  {
    title: (
      <>
        DFOS <br className="pc" />
        Viewer(App)
      </>
    ),

    engtitle: (
      <>
        DFOS <br className="pc" />
        Viewer(App)
      </>
    ),
    sub: (
      <>
        DFOS Pilot의 동일한 화면을 <br className="mb" />
        여러사람이 함께 공유하는 App
      </>
    ),

    engsub: (
      <>
        App to share the same screen of DFOS Pilot
        <br /> with multiple people
      </>
    ),
  },
];
