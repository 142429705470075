import { useState } from "react";
import "./css/index.css";
import { Link, useLocation } from "react-router-dom";
import { UserAgentType } from "../../common/common.inerface";

type Props = {
  nav_layouts: {
    title: string;
    eng?: string;
    link: string;
  }[];
  page_type: "company" | "award" | "business" | "solution" | "dron";
  userAgent: UserAgentType;
  lang?: "kor" | "eng";
};

const TopNavSection = ({ nav_layouts, page_type, userAgent, lang }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const pathname = useLocation().pathname;
  return (
    <>
      {userAgent === "mb" ? (
        <div className="drop-nav-section">
          <div className={`type-box ${isOpen ? "open" : ""}`}>
            <button
              className="now"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div className="type">
                {lang === "kor"
                  ? nav_layouts.find(
                      (nav) =>
                        pathname === nav.link ||
                        pathname.includes(nav.link + "/detail")
                    )?.title
                  : nav_layouts.find(
                      (nav) =>
                        pathname === nav.link ||
                        pathname.includes(nav.link + "/detail")
                    )?.eng}
              </div>
              <img
                className="button-type"
                src="/assets/common/arrow.svg"
                alt=""
              ></img>
            </button>

            {nav_layouts.map(({ title, link, eng }, idx) => {
              return (
                <Link
                  key={idx}
                  to={link}
                  className="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {lang !== "eng" ? title : eng}
                </Link>
              );
            })}
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div
            className={`top-nav-section ${page_type}-nav-section ${
              lang === "eng"
                ? "eng"
                : page_type === "business"
                ? "business-eng"
                : ""
            }`}
          >
            {nav_layouts.map(({ title, link, eng }, idx) => {
              return (
                <Link
                  key={idx}
                  to={link}
                  className={`top-nav ${
                    pathname === link || pathname.includes(link + "/detail")
                      ? "now-nav"
                      : ""
                  }`}
                >
                  {lang !== "eng" ? title : eng}
                </Link>
              );
            })}
            <div
              className={`top-nav-line active-line-${nav_layouts.findIndex(
                ({ link }) =>
                  pathname === link || pathname.includes(link + "/detail")
              )}`}
            />
          </div>
          <div className="line-section"></div>
        </>
      )}
    </>
  );
};

export default TopNavSection;
