import { useSelector } from "react-redux";
import {
  NewsInterface,
  UserAgentType,
} from "../../../../../common/common.inerface";
import Screen from "../../../../../components/Screen/Screen";
import { formatDate } from "../../../../../lib/lib";
import { RootState } from "../../../../../reducer";
import "./css/index.css";
import { useNavigate } from "react-router-dom";

type Props = {
  content: string;
  EngContent: string;
  data: NewsInterface;
};

const NewsDetail = ({ content, data, EngContent }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  const navigate = useNavigate();
  return (
    <main className="NewsDetail">
      <div className="news-section">
        <div className="news-detail">
          <div className="news-title">
            {lang !== "kor" && data.eng ? (
              <div className="title">{data.eng_title}</div>
            ) : (
              <div className="title">{data.title}</div>
            )}
            <div className="date">{formatDate(data.timestamp, "-")}</div>
          </div>
          <Screen data={lang !== "kor" && data.eng ? EngContent : content} />
        </div>

        <div className="btn">
          <button
            className="list-btn"
            onClick={() => {
              navigate(-1);
            }}
          >
            목록
          </button>
        </div>
      </div>
    </main>
  );
};

export default NewsDetail;
