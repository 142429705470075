import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { UserAgentType } from "./common/common.inerface";
import CompanyRoutes from "./routes/CompanyRoute/CompanyRoute";
import Header from "./components/Header/Header";
import CompanyAwardRoutes from "./routes/CompanyRoute/CompanyAwardRoute";
import CompanyNewsRoutes from "./routes/CompanyRoute/CompanyNewsRoute";
import Footer from "./components/Footer/Footer";
import BusinessRoutes from "./routes/BusinessRoute/BusinessRoute";
import GisSolutionRoute from "./routes/SolutionRoute/GisSolutionRoute";
import DronSolutionRoute from "./routes/SolutionRoute/DronSolutionRoute";
import ContactUs from "./page/ContactUs/ContactUs";
import RecrultmentDetail from "./page/Recrultment/RecrultmentDetail/RecrultmentDetail";
import MainContainer from "./page/Main/container/MainContainer";
import ScrollTop from "./components/ScrollTop/ScrollTop";
import RecrultmentContainer from "./page/Recrultment/container/RecrultmentContainer";
import ContactContainer from "./containers/Contactus/ContactContainer";

function App() {
  const [userAgent, setUserAgent] = useState<UserAgentType>("pc");

  useEffect(() => {
    function change(e: any) {
      const width = e.target.innerWidth;
      if (width < 1440 && width > 767 && userAgent !== "tablet") {
        setUserAgent("tablet");
      }
      if (width < 768 && userAgent !== "mb") {
        setUserAgent("mb");
      }
      if (width > 1439 && userAgent !== "pc") {
        setUserAgent("pc");
      }
    }
    window.addEventListener("resize", change);
    return () => {
      window.removeEventListener("resize", change);
    };
  }, [userAgent]);
  useEffect(() => {
    const size = window.innerWidth;
    if (size < 1440 && size > 767) {
      setUserAgent("tablet");
    }
    if (size < 768) {
      setUserAgent("mb");
    }
    if (size > 1439) {
      setUserAgent("pc");
    }
    return () => {};
  }, []);
  return (
    <BrowserRouter>
      <Header userAgent={userAgent} />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<MainContainer userAgent={userAgent} />} />
        <Route
          path="/company/*"
          element={<CompanyRoutes userAgent={userAgent} />}
        />
        <Route
          path="/award/*"
          element={<CompanyAwardRoutes userAgent={userAgent} />}
        />
        <Route
          path="/news/*"
          element={<CompanyNewsRoutes userAgent={userAgent} />}
        />

        <Route
          path="/business/*"
          element={<BusinessRoutes userAgent={userAgent} />}
        />

        <Route
          path="/contactus"
          element={<ContactContainer userAgent={userAgent} />}
        />

        <Route
          path="/solution/gis/*"
          element={<GisSolutionRoute userAgent={userAgent} />}
        />

        <Route
          path="/solution/dron/*"
          element={<DronSolutionRoute userAgent={userAgent} />}
        />

        <Route
          path="/recrultment"
          element={<RecrultmentContainer userAgent={userAgent} />}
        />

        <Route
          path="/recrultment/detail/*"
          element={<RecrultmentDetail userAgent={userAgent} />}
        />
      </Routes>
      <Footer userAgent={userAgent} />
    </BrowserRouter>
  );
}

export default App;
