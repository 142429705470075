import { Route, Routes } from "react-router-dom";
import TopNavSection from "../../components/TopNavSection/TopNavSection";
import { UserAgentType } from "../../common/common.inerface";

import "./css/index.css";
import BusinessService from "../../page/Business/BusinessService/BusinessService";
import IGIS from "../../page/Solution/GisSolution/IGIS/IGIS";
import IGISsystem from "../../page/Solution/GisSolution/IGISsystem/IGISsystem";
import FMS from "../../page/Solution/GisSolution/FMS/FMS";
import { RootState } from "../../reducer";
import { useSelector } from "react-redux";

type Props = {
  userAgent: UserAgentType;
};

const GisSolutionRoute = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  return (
    <main className="solution-page">
      <div className="title-section">
        <div className="left">
          <div className="title">GIS SOLUTION</div>
          {lang === "kor" && <div className="subtitle">GIS 솔루션</div>}
        </div>

        <div className="right">
          <div className="gif">
            <video autoPlay loop muted playsInline preload="auto">
              <source src="/video/solution.webm" type="video/webm" />
              <source src="/video/solution.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <TopNavSection
        nav_layouts={nav_layouts}
        page_type="solution"
        userAgent={userAgent}
        lang={lang}
      />
      <Routes>
        <Route path="/" element={<IGIS userAgent={userAgent} lang={lang} />} />
        <Route
          path="system"
          element={<IGISsystem userAgent={userAgent} lang={lang} />}
        />
        <Route path="fms" element={<FMS userAgent={userAgent} lang={lang} />} />
      </Routes>
    </main>
  );
};

export default GisSolutionRoute;

const nav_layouts = [
  {
    title: "IGIS",
    link: "/solution/gis",
    eng: "IGIS",
  },
  {
    title: "IGIS 산림 시스템",
    eng: "IGIS Forest",
    link: "/solution/gis/system",
  },

  {
    title: "FMS",
    link: "/solution/gis/fms",
    eng: "FMS",
  },
];
