import React, { useState } from "react";
import Main from "../Main";
import { NewsInterface, UserAgentType } from "../../../common/common.inerface";
type Props = {
  userAgent: UserAgentType;
};
const MainContainer = ({ userAgent }: Props) => {
  const [data, setData] = useState<NewsInterface[]>([]);
  return <Main userAgent={userAgent} data={data} />;
};

export default MainContainer;
