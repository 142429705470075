import React from "react";
import { NewsInterface } from "../../common/common.inerface";
import { formatDate } from "../../lib/lib";
import { RootState } from "../../reducer";
import { useSelector } from "react-redux";

type Props = {
  __nav: (id: string) => void;
  data: NewsInterface;
  idx: number;
};

const NewsCard = ({ __nav, data, idx }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );

  const getThumbnailImageURL = () => {
      if (data.useDefaultImage) {
          return "/assets/common/emty.png";
      }

      if (data.useThumbnail && data.thumbnailURL != "") {
          return data.thumbnailURL;
      }

        if (data.img) {
            return data.img;
        }

        return "/assets/common/emty.png";
  }

  return (
    <button className="news" onClick={() => __nav(data.id)}>
      <div
        className="left"
        style={{
          backgroundImage: `url(${getThumbnailImageURL()})`,
        }}
      ></div>
      <div className="right">
        <div className="top">
          {lang !== "kor" && data.eng ? (
            <div className="title">{data.eng_title}</div>
          ) : (
            <div className="title">{data.title}</div>
          )}

          {lang !== "kor" && data.eng ? (
            <div className="text">{data.eng_text}</div>
          ) : (
            <div className="text">{data.text}</div>
          )}
        </div>
        <div className="date">{formatDate(data.timestamp, "-")}</div>
      </div>
    </button>
  );
};

export default NewsCard;
