import React from "react";
import "./css/index.css";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import { RootState } from "../../reducer";

type Props = {
  initialLoading?: boolean;
};
const Loading = ({ initialLoading }: Props) => {
  const isLoading = useSelector<RootState, boolean>(
    (state) => state.config.isLoading
  );
  return isLoading || initialLoading ? (
    <div
      className="loading-section"
      style={
        initialLoading
          ? {
              width: "100vw",
              height: "100vh",
              position: "relative",
            }
          : undefined
      }
    >
      <div className="back-black" />
      <Oval
        width={100}
        height={100}
        color="var(--main)"
        secondaryColor="#fffff"
      />
    </div>
  ) : (
    <></>
  );
};

export default Loading;
