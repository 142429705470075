import React, { useEffect, useState } from "react";
import {
  NewsInterface,
  UserAgentType,
} from "../../../../../../common/common.inerface";
import NewsDetail from "../NewsDetail";
import { doc, getDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { db } from "../../../../../../config/firebase";
type Props = {
  userAgent: UserAgentType;
};
const NewsDetailContainer = ({ userAgent }: Props) => {
  const id = useLocation().pathname.split("/")[3];
  const [DetailData, setDetailData] = useState<NewsInterface | undefined>(
    () => undefined
  );
  const [Content, setContent] = useState<string>("");
  const [EngContent, setEngContent] = useState<string>("");
  useEffect(() => {
    getDoc(doc(db, "news", id)).then((snapshot) => {
      const value = snapshot.data() as NewsInterface;
      setDetailData(value);

      if (value.content) {
        getDoc(doc(db, "detail", value.content)).then((res) => {
          const data = res.data();
          if (data) {
            setContent(data.content);
            if (data.eng) {
              setEngContent(data.eng);
            }
          }
        });
      }
    });

    return () => {};
  }, [id]);

  if (DetailData) {
    return (
      <NewsDetail content={Content} data={DetailData} EngContent={EngContent} />
    );
  }
  return <></>;
};

export default NewsDetailContainer;
