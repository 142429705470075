import React from "react";

import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const BusinessGIS = ({ userAgent, lang }: Props) => {
  return (
    <main className="BusinessGIS">
      <div className="main-section">
        <div className="title-section">
          {lang === "kor" ? (
            <>
              {" "}
              GIS의 수준높은 <br /> <span>맞춤형 서비스</span> 제공
            </>
          ) : (
            <>
              High-quality <br />
              <span>customized GIS services </span>
            </>
          )}
        </div>

        <div className="content-section">
          {box.map((item, index) => {
            return (
              <div key={index} className="box">
                <div className="title">
                  {" "}
                  {lang === "kor" ? item.title : item.eng}
                </div>
                <div className="sub">
                  {lang === "kor"
                    ? item.sub.map((item) => item)
                    : item.engsub.map((item) => item)}
                </div>
                <div className="img">
                  <img src={item.img} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default BusinessGIS;

const box = [
  {
    title: "시스템 판매",
    eng: "System Sales",
    sub: [
      <div>
        <div className="dot"></div>
        <div>
          고객의 니즈를 이해하고 최적의 <br />
          GIS 시스템 판매를 목표로 하고 있습니다.
        </div>
      </div>,
    ],
    engsub: [
      <div>
        <div className="dot"></div>
        <div className="div">
          We aim to provide our clients with the best GIS system by
          understanding and meeting their needs
        </div>
      </div>,
    ],

    img: "/assets/business/gis/pc/gis-icon1.svg",
  },

  {
    title: "시스템 교육 및 AS",
    eng: "System Training & After-sales Service",
    sub: [
      <div>
        <div className="dot"></div>
        <div>
          교육을 통해 최상의 결과물을 만들 수 있습니다.
          <br />
          최신 기술에 대한 학습을 통해 신뢰성 있는 결과를 <br /> 얻기 위한 더
          빠른 방법과 정보를 얻으실 수 있습니다
        </div>
      </div>,
      <div>
        <div className="dot"></div>
        <div>
          AS를 통해 고객 여러분의 안정적이고 효율적인 <br />
          시스템 운영을 지원합니다.
        </div>
      </div>,
    ],
    engsub: [
      <div>
        <div className="dot"></div>
        <div className="div">
          System training enables users to achieve optimal results, by learning
          the latest technology and improving speed and reliability
        </div>
      </div>,
      <div>
        <div className="dot"></div>
        <div className="div">
          We provide after-sales support to ensure steady and effective system
          operation
        </div>
      </div>,
    ],
    img: "/assets/business/gis/pc/gis-icon2.svg",
  },

  {
    title: "업무 단위 시스템 구축",
    eng: "Customized System",
    sub: [
      <div>
        <div className="dot"></div>
        <div>
          고객에 맞춤형 지리정보 시스템을 구축하여 <br />
          최적의 솔루션 및 서비스를 제공합니다.
        </div>
      </div>,
    ],
    engsub: [
      <div>
        <div className="dot"></div>
        <div className="div">
          We provide the optimal solution for clients by establishing a
          customized GIS system
        </div>
      </div>,
    ],
    img: "/assets/business/gis/pc/gis-icon3.svg",
  },

  {
    title: "지리정보 데이터 가공, 분석",

    eng: "Geographic Data Processing & Analysis",
    sub: [
      <div>
        <div className="dot"></div>
        <div>
          비즈니스 혁신 및 신규 제품·서비스 개발 등에 <br />
          활용될 수 있는 고객이 필요한 지리정보데이터의 <br />
          분석 및 가공 서비스를 지원합니다.
        </div>
      </div>,
    ],
    engsub: [
      <div>
        <div className="dot"></div>
        <div className="div">
          We offer geographic data processing and analysis functions to support
          business innovation and the development of new products and services
        </div>
      </div>,
    ],

    img: "/assets/business/gis/pc/gis-icon4.svg",
  },

  {
    title: "월 정책 (민간시장 적용)",
    eng: "Monthly Pricing for Private Entities",
    sub: [
      <div>
        <div className="dot"></div>
        <div>
          민간시장의 사용자들은 위해 금액부담이 부담이 큰<br /> 일회성 구매 대신
          월별 요금이나 연간 요금을 <br />
          지불하여 사용이 가능합니다.
        </div>
      </div>,
    ],
    engsub: [
      <div>
        <div className="dot"></div>
        <div className="div">
          We offer flexible payment options, including monthly and annual
          payments, to our private sector clients
        </div>
      </div>,
    ],

    img: "/assets/business/gis/pc/gis-icon5.svg",
  },
];
