import { Route, Routes } from "react-router-dom";
import TopNavSection from "../../components/TopNavSection/TopNavSection";
import CompanyIntro from "../../page/Company/CompanyInfo/CompanyIntro/CompanyIntro";
import { UserAgentType } from "../../common/common.inerface";
import CompanyHistory from "../../page/Company/CompanyInfo/CompanyHistory/CompanyHistory";
import "./css/index.css";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import ContactContainer from "../../containers/Contactus/ContactContainer";
import ContactWay from "../../page/ContactWay/ContactWay";
type Props = {
  userAgent: UserAgentType;
};

export interface HistoryType {
  year: number;
  content: string;
  eng: string;
}

const CompanyRoutes = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  const [historyData, setHistoryData] = useState<HistoryType[]>([]);

  useEffect(() => {
    getDoc(doc(db, "history", "basic")).then((result) => {
      const value: any = result.data();
      const list: any[] = value.list.sort((a: any, b: any) => b.year - a.year);
      setHistoryData(list);
    });
    return () => {};
  }, []);

  return (
    <main className="company-page">
      <div className="title-section">
        <div className="left">
          <div className="title">COMPANY</div>
          {lang === "kor" && <div className="subtitle">기업소개</div>}
        </div>

        <div className="right">
          <div className="gif">
            <video autoPlay loop muted playsInline preload="auto">
              <source src="/video/company.webm" type="video/webm" />
              <source src="/video/company.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <TopNavSection
        nav_layouts={nav_layouts}
        page_type="company"
        lang={lang}
        userAgent={userAgent}
      />
      <Routes>
        <Route
          path="/"
          element={<CompanyIntro userAgent={userAgent} lang={lang} />}
        />
        <Route
          path="info"
          element={
            <CompanyHistory
              userAgent={userAgent}
              lang={lang}
              data={historyData}
            />
          }
        />

        <Route
          path="contactus"
          element={<ContactWay userAgent={userAgent} />}
        />
      </Routes>
    </main>
  );
};

export default CompanyRoutes;

const nav_layouts = [
  {
    title: "조직도",
    eng: "Team",
    link: "/company",
  },
  {
    title: "연혁",
    eng: "History",
    link: "/company/info",
  },

  {
    title: "오시는길",
    eng: "Contact us",
    link: "/company/contactus",
  },
];
