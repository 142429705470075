import React from "react";

import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import Solution from "../../../../components/Solution/Solution";

type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const IGISsystem = ({ userAgent, lang }: Props) => {
  return (
    <main className="IGIS">
      <Solution
        lang={lang}
        gradationType=""
        gradation={false}
        box_titles={box_titles}
        type="IGISsystem"
        box_layouts={box_layouts}
        userAgent={userAgent}
        src="/assets/solution/gis/igissystem/pc/igis-img.png"
        srcSet="/assets/solution/gis/igissystem/pc/igis-img@2x.png 2x, /assets/solution/gis/igissystem/pc/igis-img@3x.png 3x"
      />
    </main>
  );
};

export default IGISsystem;

const box_layouts = [
  {
    point: "Point 01",
    title: (
      <>
        전문적인 프로그램으로 <br />
        산림지역에서 효율적인 기능
      </>
    ),

    engtitle: <>Optimized GIS for Forestry Work</>,
    engsub: (
      <>
        {" "}
        <div className="dot"></div>Skidding trails and forest roads planning
      </>
    ),
    engsub1: (
      <>
        {" "}
        <div className="dot"></div>Longitudinal and cross section production
      </>
    ),
    engsub2: (
      <>
        {" "}
        <div className="dot"></div>Print-output and layout
      </>
    ),
    engsub3: (
      <>
        {" "}
        <div className="dot"></div>Eco-friendly logging design{" "}
      </>
    ),
    engsub4: (
      <>
        {" "}
        <div className="dot"></div>
        Analysis (surface distance, area, visibility range, water flow,
        gradient)
      </>
    ),
    sub: (
      <>
        <div className="dot"></div>
        <div>운재로 및 임도에대한 설계지원 기능</div>
      </>
    ),

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        종단면도, 횡단면도 제작 기능
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>프린트 출력 및 레이아웃 제공
      </>
    ),

    sub3: (
      <>
        {" "}
        <div className="dot"></div>산림 벌재 업무에 필요한 친환경 벌채{" "}
        <br className="mb" /> 설계 기능 지원
      </>
    ),

    sub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>
          분석 기능 제공(표면거리, 표면적, 가시권, 수계흐름, 경사도 분석)
        </div>
      </>
    ),
    src: "/assets/solution/gis/igissystem/pc/igis-boximg1.png",
    srcSet:
      "/assets/solution/gis/igissystem/pc/igis-boximg1@2x.png 2x, /assets/solution/gis/igissystem/pc/igis-boximg1@3x.png 3x",
  },

  {
    point: "Point 02",
    title: (
      <>
        산림지형정보처리를 <br /> 위한 사용자 친화적 GIS
      </>
    ),

    engtitle: <>User-friendly GIS for Forest Geospatial Data Handling</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>
          공간정보를 이용하여 산림행정 업무가 필요한 <br />
          국내외 공공기관 및 민간회사 모두 사용가능한 산림공간 정보시스템
        </div>
      </>
    ),
    engsub: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          Convenient GIS accessible to both domestic and
          <br className="pc" /> international public institutions <br />
          and private companies that require forest geospatial data
        </div>
      </>
    ),

    src: "/assets/solution/gis/igissystem/pc/igis-boximg2.png",
    srcSet:
      "/assets/solution/gis/igissystem/pc/igis-boximg2@2x.png 2x, /assets/solution/gis/igissystem/pc/igis-boximg2@3x.png 3x",
    type: "reverse",
  },
];

const box_titles = [
  {
    title: (
      <>
        IGIS(FOREST) <br className="pc" />
        업무를 <br className="tablet" />
        <br className="mb" />
        위한 산림공간 정보시스템
      </>
    ),

    engtitle: (
      <>
        IGIS(FOREST) <br className="pc" />
        Forest Spatial Information
        <br className="pc" /> System for Forest Management
      </>
    ),
    sub: (
      <>
        산림업무에 최적화된 시스템으로 <br className="mb" />
        산림공간정보에 대한 편집
        <br className="pc" />
        <br className="tablet" /> 분석 및 3D 모델링이 가능한 산림 전문 GIS
        Solution
      </>
    ),

    engsub: (
      <>
        Specialized forest GIS solution to enable editing, analyzing, and{" "}
        <br className="pc" />
        <br className="tablet" />
        3D modeling of forest geospatial info through optimized functions
        <br className="pc" /> for forestry work
      </>
    ),
  },
];
