import axios from "axios";
import React, { useCallback, useReducer } from "react";
import ContactUs from "../../page/ContactUs/ContactUs";
import { UserAgentType } from "../../common/common.inerface";
import { ContactReducer } from "../../lib/reducer";
import { ContactInitial } from "../../lib/initial";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useDispatch } from "react-redux";
import { LoadingChange } from "../../reducer/config";
import {MailContent} from "./MailContent";

type Props = {
  userAgent: UserAgentType;
};
const ContactContainer = ({ userAgent }: Props) => {
  const rxDispatch = useDispatch();
  const [state, dispatch] = useReducer(
    ContactReducer,
    undefined,
    ContactInitial
  );
  const SaveContact = useCallback(async () => {
    rxDispatch(LoadingChange(true));

    try {
      const obj = Object.assign({ timestamp: Date.now() }, state);

      await addDoc(collection(db, "contact"), obj);
      sendEmail(obj);

      alert("문의가 성공적으로 완료되었습니다.");

      dispatch({
        type: "reset",
        payload: "",
      });

      window.location.reload();
      rxDispatch(LoadingChange(true));
    } catch (error) {
      rxDispatch(LoadingChange(false));
    }
  }, [state]);

  const sendEmail = (obj: any) => {
    axios.post("http://218.238.75.22:52000/email", {
      "to": "ceo@igis.co.kr",
      "title": "문의 등록 알림",
      "content": MailContent(obj)
    }).then(_ => {});
  }

  return (
    <ContactUs
      userAgent={userAgent}
      data={state}
      dispatch={dispatch}
      SaveContact={SaveContact}
    />
  );
};

export default ContactContainer;