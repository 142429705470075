import "./css/index.css";
import { UserAgentType } from "../../common/common.inerface";
import { useLocation } from "react-router-dom";

type Props = {
  box_layouts: any;
  box_titles: any;
  userAgent: UserAgentType;
  src: any;
  srcSet: any;
  gradationType: string;
  type: string;
  gradation: boolean;
  lang?: "kor" | "eng";
};

const Solution = ({
  box_titles,
  box_layouts,
  src,
  srcSet,
  gradationType,
  type,
  gradation,
  lang,
}: Props) => {
  const pathname = useLocation().pathname;
  console.log(pathname);

  return (
    <>
      <section className="solution">
        <div className="main-section">
          <div className={`title-wrapper ${type}`}>
            {box_titles.map((item: any, index: any) => {
              return (
                <div key={index} className="title-section">
                  <div className="title">
                    {" "}
                    {lang === "kor" ? item.title : item.engtitle}
                  </div>
                  <div className="sub">
                    {lang === "kor" ? item.sub : item.engsub}
                  </div>
                </div>
              );
            })}

            <div className="img-section">
              <img src={src} srcSet={srcSet} alt="" />
            </div>
          </div>
          {gradation === true && (
            <div className={`gradation ${gradationType}`}></div>
          )}

          {pathname === "/solution/dron/pilot" && (
            <div className="pilot-box-wrapper">
              <div className="title">
                {lang === "kor" ? (
                  <>
                    {" "}
                    폭넓은 호환성으로 <br />
                    다양한 기체에도 적용할 수 있습니다{" "}
                  </>
                ) : (
                  <>Wide Compatibility</>
                )}
              </div>

              <div className="box">
                {lang === "kor" ? (
                  <>
                    {" "}
                    {pilot.map((item, index) => {
                      return (
                        <div key={index} className="pilot-box">
                          {item}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {" "}
                    {engPilot.map((item, index) => {
                      return (
                        <div key={index} className="pilot-box">
                          {item}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}

          <div className="content-section">
            {box_layouts.map((item: any, index: any) => {
              return (
                <>
                  <div key={index} className={`content ${item.type}`}>
                    {item.etc === "subtitle" ? (
                      <div className={`box ${lang === "eng" && item.engtype}`}>
                        <div className="point-wrapper">
                          <div className="point">{item.point}</div>
                        </div>
                        <div className="title">
                          {" "}
                          {lang === "kor" ? item.title : item.engtitle}
                        </div>

                        <div
                          className={`subtitle-wrapper ${
                            lang === "eng" ? "eng-wrapper" : ""
                          }`}
                        >
                          <div className="left">
                            {" "}
                            {lang === "kor"
                              ? item.subtitle1
                              : item.engsubtitle1}
                          </div>
                          <div className="right">
                            {" "}
                            <div className="sub">
                              {" "}
                              {lang === "kor" ? item.sub : item.engsub}
                            </div>
                          </div>
                        </div>

                        <div
                          className={`subtitle-wrapper ${
                            lang === "eng" ? "eng-wrapper" : ""
                          }`}
                        >
                          <div className="left">
                            {lang === "kor"
                              ? item.subtitle2
                              : item.engsubtitle2}
                          </div>
                          <div className="right">
                            {" "}
                            <div className="sub">
                              {" "}
                              {lang === "kor" ? item.sub1 : item.engsub1}
                            </div>
                            <div className="sub">
                              {" "}
                              {lang === "kor" ? item.sub2 : item.engsub2}
                            </div>
                            <div className="sub">
                              {" "}
                              {lang === "kor" ? item.sub3 : item.engsub3}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`box ${
                          pathname === "/solution/dron/ims" ? "dot-box" : ""
                        }`}
                      >
                        <div className="point-wrapper">
                          <div className="point">{item.point}</div>
                        </div>
                        <div className="title">
                          {" "}
                          {lang === "kor" ? item.title : item.engtitle}
                        </div>
                        <div className="sub">
                          {" "}
                          {lang === "kor" ? item.sub : item.engsub}
                        </div>

                        <div className="sub">
                          {lang === "kor" ? item.sub1 : item.engsub1}
                        </div>
                        <div className="sub">
                          {" "}
                          {lang === "kor" ? item.sub2 : item.engsub2}
                        </div>
                        <div className="sub">
                          {" "}
                          {lang === "kor" ? item.sub3 : item.engsub3}
                        </div>
                        <div className="sub">
                          {" "}
                          {lang === "kor" ? item.sub4 : item.engsub4}
                        </div>
                        <div className="sub">
                          {" "}
                          {lang === "kor" ? item.sub5 : item.engsub5}
                        </div>
                        <div className="sub">
                          {" "}
                          {lang === "kor" ? item.sub6 : item.engsub6}
                        </div>
                        <div className="sub">
                          {" "}
                          {lang === "kor" ? item.sub7 : item.engsub7}
                        </div>
                      </div>
                    )}

                    <div
                      className={`img-box ${lang === "eng" && item.engtype}`}
                    >
                      <img src={item.src} srcSet={item.srcSet} alt="" />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Solution;

const pilot = [
  "수소연료전지 드론(두산)",
  "A3 (Light brige)",
  "Mavic2 시리즈",
  "Mavic2 Enterprise Dual",
  "Phantom3 시리즈",
  "Phantom4 시리즈",
  "Inspire1",
  "Inspire2",
  "Matrice 200 시리즈",
  "Matrice 600 시리즈",
  "Matrice 300 RTK",
];

const engPilot = [
  "Hydrogen Fuel Cell",
  "A3 + Lightbridge",
  "Mavic2 Series",
  "Mavic2 Enterprise Dual",
  "Phantom3 Series",
  "Phantom4 Series",
  "Inspire1",
  "Inspire2",
  "Matrice 200 Series",
  "Matrice 600 Series",
];
