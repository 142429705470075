import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../../../config/firebase";
import {
  NewsInterface,
  UserAgentType,
} from "../../../../../common/common.inerface";
import CompanyVideo from "../CompanyVideo";
import Loading from "../../../../../components/Loading/Loading";
type Props = {
  userAgent: UserAgentType;
};
const VideoContainer = ({ userAgent }: Props) => {
  const [data, setData] = useState<NewsInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    setIsLoading(true);
    getDocs(
      query(
        collection(db, "video"),
        where("isBlind", "!=", 1),
        orderBy("isBlind"),
        orderBy("timestamp", "desc")
        // limit(15)
      )
    ).then((snapshot) => {
      let arr: NewsInterface[] = [];
      snapshot.forEach((item) => {
        arr.push(item.data() as NewsInterface);
      });
      setIsLoading(false);
      setData(arr);
    });

    return () => {};
  }, []);
  if (isLoading) {
    return <Loading initialLoading={true} />;
  }
  return <CompanyVideo userAgent={userAgent} data={data} />;
};

export default VideoContainer;
