import React, { useCallback } from "react";
import "./css/index.css";
import Map from "../../components/map/map";
import { UserAgentType } from "../../common/common.inerface";
import { RootState } from "../../reducer";
import { useSelector } from "react-redux";
import Contact from "../../components/Contact/Contact";
import { ContactInter } from "../../lib/reducer";
type Props = {
  userAgent: UserAgentType;
  data: ContactInter;
  dispatch: React.Dispatch<{
    payload: any;
    type: string;
    index?: number;
  }>;
  category?: string;

  SaveContact: () => void;
};
const ContactUs = ({ userAgent, data, dispatch, SaveContact }: Props) => {
  const CommonUpdate = useCallback(
    (type: string, value: any, index?: number) => {
      dispatch({
        type,
        payload: value,
        index,
      });
    },
    [dispatch]
  );
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  return (
    <main className="ContactU">
      <Contact
        title={lang === "kor" ? "고객 정보" : "Customer information"}
        SaveContact={SaveContact}
        data={data}
        userAgent={userAgent}
        Update={CommonUpdate}
        category="customer"
      />

      <Contact
        title={lang === "kor" ? "문의하기" : "Contact us"}
        SaveContact={SaveContact}
        data={data}
        userAgent={userAgent}
        Update={CommonUpdate}
        category="contact"
      />

      <Contact
        title={
          lang === "kor"
            ? "개인정보 처리방침 동의"
            : "Consent to Privacy Policy"
        }
        SaveContact={SaveContact}
        data={data}
        userAgent={userAgent}
        Update={CommonUpdate}
        category="agreement"
      />
    </main>
  );
};

export default ContactUs;

const communication = [
  {
    Text: (
      <>
        PHONE <br />
        <span>070-8740-5534</span>
      </>
    ),
  },
  {
    Text: (
      <>
        FAX <br />
        <span>070-8740-1730</span>
      </>
    ),
  },

  {
    Text: (
      <>
        E-mail <br />
        <span>ceo@igis.co.kr</span>
      </>
    ),
  },
];
