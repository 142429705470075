import React, { useCallback, useEffect, useRef, useState } from "react";
import { NewsInterface } from "../../common/common.inerface";
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  limit,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { formatDate } from "../../lib/lib";
import { NavigateFunction } from "react-router-dom";
type Props = {
  lang: "kor" | "eng";
  navigate: NavigateFunction;
};

const MainNews = ({ lang, navigate }: Props) => {
  const [data, setData] = useState<NewsInterface[]>([]);
  const [now, setNow] = useState<number>(0);
  const [scroll, setScroll] = useState<number>(0);
  useEffect(() => {
    getDocs(
      query(
        collection(db, "news"),
        where("isBlind", "!=", 1),
        orderBy("isBlind"),
        orderBy("timestamp", "desc"),
        limit(9)
      )
    ).then((snapshot) => {
      let arr: NewsInterface[] = [];
      snapshot.forEach((item) => {
        arr.push(item.data() as NewsInterface);
      });
      setData(arr);
    });

    return () => {};
  }, []);
  return (
    <div className="news-section">
      <div className="title-wrapper">
        <div className="title">IGIS NEWS</div>
        <div className="sub">
          {lang === "kor"
            ? "IGIS 소식을 전해드립니다"
            : "We bring you our latest news"}
        </div>
      </div>
      <div
        className="news-wrapper"
        onScroll={(e) => {
          const width = e.currentTarget.scrollWidth;
          const left = e.currentTarget.scrollLeft;
          console.log((100 * left) / (width - e.currentTarget.clientWidth));
          setScroll((100 * left) / (width - e.currentTarget.clientWidth));
        }}
      >
        <div
          className="news-container"
          style={{ transform: `translateX(-${now * 1125}px)` }}
        >
          {data.map((item, idx) => {
            return (
              <button
                key={idx}
                className="news"
                style={{ backgroundImage: `url(${item.img})` }}
                onClick={() => {
                  navigate(`/news/detail/${item.id}`);
                }}
              >
                <div className="bottom">
                  <div className="date">{formatDate(item.timestamp, "-")}</div>
                  <div className="title">{item.title}</div>
                  <div className="text">{item.text}</div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
      {data.length > 3 ? (
        <div className="scrollbar">
          <div
            className="activebar"
            style={{
              marginLeft: `${scroll < 90 ? scroll : 90}%`,
            }}
          ></div>
        </div>
      ) : undefined}

      <div className="slider-nav">
        {now !== 0 && (
          <button
            onClick={() => {
              setNow((prev) => prev - 1);
            }}
            className="prev-btn"
          >
            <img src="/assets/common/left-arrow.svg" alt="" />
          </button>
        )}
        {Math.ceil(data.length / 3) > now + 1 ? (
          <button
            onClick={() => {
              setNow((prev) => prev + 1);
            }}
            className="next-btn"
          >
            <img src="/assets/common/right-arrow.svg" alt="" />
          </button>
        ) : undefined}
      </div>
    </div>
  );
};

export default MainNews;
