import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import Solution from "../../../../components/Solution/Solution";

type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const FMS = ({ userAgent, lang }: Props) => {
  return (
    <main className="FMS">
      <Solution
        lang={lang}
        gradationType=""
        box_titles={box_titles}
        gradation={false}
        type="FMS"
        box_layouts={box_layouts}
        userAgent={userAgent}
        src="/assets/solution/gis/fms/pc/igis-img.png"
        srcSet="/assets/solution/gis/fms/pc/igis-img@2x.png 2x, /assets/solution/gis/fms/pc/igis-img@3x.png 3x"
      />
    </main>
  );
};

export default FMS;

const box_layouts = [
  {
    point: "Point 01",
    title: (
      <>
        현장업무 생산성을 높이는 <br />
        효율적인 업무툴 FMS
      </>
    ),
    engtitle: <>FMS Enhances Your Work Efficiency</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>On/Off Line 배경지도 지원</div>
      </>
    ),
    engsub: (
      <>
        {" "}
        <div className="dot"></div>On/Off Line background map
      </>
    ),
    engsub1: (
      <>
        {" "}
        <div className="dot"></div>Viewer function for design drawings &
        completion drawings
      </>
    ),
    engsub2: (
      <>
        {" "}
        <div className="dot"></div>SHP, KML File supported
      </>
    ),
    engsub3: (
      <>
        {" "}
        <div className="dot"></div>Video service supported (WMS etc.)
      </>
    ),
    engsub4: (
      <>
        {" "}
        <div className="dot"></div>Memo function
      </>
    ),
    engsub5: (
      <>
        {" "}
        <div className="dot"></div>Tracking function
      </>
    ),

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        설계 도면 및 준공 도면 Viewer 기능 탑재
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>Shape, KML File 지원
      </>
    ),

    sub3: (
      <>
        {" "}
        <div className="dot"></div>영상 서비스(WMS 등) 지원
      </>
    ),

    sub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>메모 기능 지원</div>
      </>
    ),

    sub5: (
      <>
        {" "}
        <div className="dot"></div>
        <div>트래킹 기능 지원</div>
      </>
    ),
    src: "/assets/solution/gis/fms/pc/igis-boximg1.png",
    srcSet:
      "/assets/solution/gis/fms/pc/igis-boximg1@2x.png 2x, /assets/solution/gis/fms/pc/igis-boximg1@3x.png 3x",
  },

  {
    point: "Point 02",
    title: <>다양한 곳에서 활용가능한 FMS</>,
    engtitle: <>Versatile Application</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>
          도시개발, 주택건설사업 등에 필요한 현장 <br />
          토지측량을 수행할 공공기관 및 업체 사용가능
        </div>
      </>
    ),

    engsub: (
      <>
        {" "}
        <div className="dot"></div>
        Public and private entities in urban
        <br /> development, construction projects, land <br />
        surveying, etc.
      </>
    ),

    src: "/assets/solution/gis/fms/pc/igis-boximg2.png",
    srcSet:
      "/assets/solution/gis/fms/pc/igis-boximg2@2x.png 2x, /assets/solution/gis/fms/pc/igis-boximg2@3x.png 3x",
    type: "reverse",
  },
];

const box_titles = [
  {
    title: (
      <>
        FMS <br className="pc" /> 현장 관리 시스템
      </>
    ),
    engtitle: (
      <>
        Fieldwork <br />
        Management System
      </>
    ),
    sub: (
      <>
        지리정보를 기반으로 현장업무를
        <br className="mb" /> 지원하는 Mobile APP으로
        <br className="pc" /> <br className="tablet" />
        현장 업무에 필요한 최적화의 기능을 지원하는 편리한 Solution
      </>
    ),

    engsub: (
      <>
        Mobile GIS Solution based on GIS map to support location-based
        <br className="pc" /> <br className="tablet" /> research and editing
        required for field investigation
      </>
    ),
  },
];
