import React, { useEffect, useState } from "react";
import {
  RecruitInterface,
  UserAgentType,
} from "../../../common/common.inerface";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import Recrultment from "../Recrultment";
import { db } from "../../../config/firebase";
import Loading from "../../../components/Loading/Loading";
type Props = {
  userAgent: UserAgentType;
};
const RecrultmentContainer = ({ userAgent }: Props) => {
  const [data, setData] = useState<RecruitInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    setIsLoading(true);
    getDocs(
      query(
        collection(db, "recruit"),
        orderBy("timestamp", "desc")
        // limit(15)
      )
    ).then((snapshot) => {
      let arr: RecruitInterface[] = [];
      snapshot.forEach((item) => {
        arr.push(item.data() as RecruitInterface);
      });
      setIsLoading(false);
      setData(arr);
    });

    return () => {};
  }, []);
  if (isLoading) {
    return <Loading initialLoading={true} />;
  }
  return <Recrultment userAgent={userAgent} data={data} />;
};

export default RecrultmentContainer;
