import React from "react";
import { UserAgentType } from "../../../../common/common.inerface";
import "./css/index.css";
type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const CompanyIntro = ({ userAgent, lang }: Props) => {
  return (
    <main className="CompanyIntro">
      <div className="backround-section">
        <div className="shadow"></div>
        <div className={`title-section ${lang === "eng" ? "title-eng" : ""}`}>
          <div className="title">{lang === "kor" ? "조직도" : "Team"}</div>
          <div className="sub">
            {lang === "kor" ? (
              <>
                {" "}
                단단한 조직력, 차별화된 기술, <br />{" "}
                <span>IGIS는 함께 만들어 갑니다</span>
              </>
            ) : (
              <>
                {" "}
                Together, we keep growing with a <br className="pc" />
                <span> strong team and unique technology</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="img-section">
        {lang === "kor" ? (
          <img
            src={`/assets/company/companyintro/${userAgent}/company.svg`}
            alt=""
          />
        ) : (
          <img
            src={`/assets/company/companyintro/${userAgent}/company-eng.svg`}
            alt=""
          />
        )}
      </div>
    </main>
  );
};

export default CompanyIntro;
