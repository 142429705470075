import React from "react";

import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const BusinessDron = ({ userAgent, lang }: Props) => {
  return (
    <main className="BusinessDron">
      <div className="main-section">
        <div className="title-section">
          {lang === "kor" ? (
            <>
              {" "}
              누구나 편리한 <br />
              드론 시스템
            </>
          ) : (
            <>
              User-friendly <br />
              drone system for all
            </>
          )}
        </div>

        <div className="content-section">
          {box.map((item, index) => {
            return (
              <div key={index} className={`box ${item.type}`}>
                <div className="title">
                  {lang === "kor" ? item.title : item.engtitle}
                </div>
                <div className="subtitle">
                  {" "}
                  {lang === "kor" ? item.subtitle : item.engsubtitle}
                </div>

                <div className="sub">
                  {" "}
                  {lang === "kor" ? item.sub : item.engsub}
                </div>
                <div className="sub">
                  {" "}
                  {lang === "kor" ? item.subText : item.engsubtext}
                </div>
                <div className="sub sub_">
                  {" "}
                  {lang === "kor" ? item.sub1 : item.engsub1}
                </div>
                <div className="sub sub_">
                  {" "}
                  {lang === "kor" ? item.sub2 : item.engsub2}
                </div>
                <div className="sub sub_">
                  {" "}
                  {lang === "kor" ? item.sub3 : item.engsub3}
                </div>
                <div className="sub sub_">
                  {" "}
                  {lang === "kor" ? item.sub4 : item.engsub4}
                </div>
                <div className="sub sub_">
                  {" "}
                  {lang === "kor" ? item.sub5 : item.engsub5}
                </div>

                <div className="img">
                  <img src={item.img} alt="" />
                </div>
                <div className="line"></div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default BusinessDron;

const box = [
  {
    title: "시스템 개별 판매",
    engtitle: "Individual Solution Sales",
    subtitle: "사용자의 요구에 따라 각 시스템 개별 구매 가능",

    engsubtitle: (
      <>
        <div className="dot"></div>
        <div className="div">
          DFOS drone platform is an integrated system with individual solutions
          and clients can purchase individual platform components as needed
        </div>
      </>
    ),
    sub1: (
      <>
        <div className="dot"></div>
        DFOS Pilot : 드론의 사진, 동영상, 촬영 및 경로 비행에 대한 사용자가{" "}
        <br className="tablet" />
        편리하게 드론 컨트롤을 할 수 있는 APP
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div className="div">DFOS Pilot , DFOS Streaming Viewer</div>
      </>
    ),

    engsub2: (
      <>
        <div className="dot"></div>
        <div className="div">DFOS Panorama</div>
      </>
    ),

    engsub3: (
      <>
        <div className="dot"></div>
        <div className="div">DFOS IMS , DFOS Viewer</div>
      </>
    ),

    engsub4: (
      <>
        <div className="dot"></div>
        <div className="div">DFOS Control System</div>
      </>
    ),

    engsub5: (
      <>
        <div className="dot"></div>
        <div className="div">DFOS Pilot, DFOS Weather</div>
      </>
    ),

    sub2: (
      <>
        <div className="dot"></div>
        DFOS Viewer : DFOS Pilot의 동일한 화면을 여러사람이 함께 공유하는 APP
      </>
    ),

    sub3: (
      <>
        <div className="dot"></div>
        DFOS Control System : 드론에서 얻은 데이터, 사진, 동영상에 대한 빅데이터
        관제시스템
      </>
    ),
    sub4: (
      <>
        <div className="dot"></div>
        DFOS Streaming Viewer : 여러개의 동영상을 편리하게 재생할 수 있는
        스트리밍뷰어
      </>
    ),
    sub5: (
      <>
        <div className="dot"></div>
        DFOS IMS : 자동정사영상 제작을 지원하며 확보된 드론영상을 효과적으로
        등록·관리·분석 하는 시스템
      </>
    ),

    type: "text",
  },

  {
    title: "용역 및 제안사업",
    engtitle: "Business Proposals and Services",
    sub: (
      <>
        <div className="dot"></div>
        <div>
          보유 중인 기술과 솔루션을 용역 및 제안 사업에 <br />
          적용하여 고객의 전문성과 역량 강화를 제시
        </div>
      </>
    ),
    engsub: (
      <>
        <div className="dot"></div>
        <div className="div">
          We apply our technology and solutions to service and business
          proposals, providing customers with enhanced expertise and competence
        </div>
      </>
    ),

    img: "/assets/business/dron/dron-icon1.svg",
  },

  {
    title: "월 정책 (민간시장 적용)",
    engtitle: "Monthly policy",
    sub: (
      <>
        <div className="dot"></div>
        <div>드론 앱 월 정책 및 드론 웹 관제시스템 월 정책</div>
      </>
    ),
    engsub: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          Drone app wall policy and drone web control system wall policy
        </div>
      </>
    ),
    subText: (
      <>
        <div className="dot"></div>
        <div className="div">
          민간시장의 사용자들을 위해 금액부담이 부담이 <br />큰 일회성 구매 대신
          월별 요금이나 연간 요금을 <br /> 지불하여 사용이 가능함
        </div>
      </>
    ),

    engsubtext: (
      <>
        <div className="dot"></div>
        <div className="div">
          For users in the private market, it is possible to use it by paying a
          monthly or annual fee instead of an expensive one-time purchase.
        </div>
      </>
    ),

    img: "/assets/business/dron/dron-icon2.svg",
  },

  {
    title: "드론 촬영",
    engtitle: "Drone Photography",
    sub: (
      <>
        <div className="dot"></div>
        DB구축 / 데이터 가공 및 분석
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div className="div">
          Database construction <br /> Data processing and analysis
        </div>
      </>
    ),

    subText: (
      <>
        <div className="dot"></div>
        <div>
          드론으로 촬영된 영상을 정사영상, 파노라마 영상 등<br />
          으로의 가공을 지원하며, 인공지능 학습용 데이터의
          <br /> 수집과 가공을 지원합니다.
        </div>
      </>
    ),

    engsubtext: (
      <>
        <div className="dot"></div>
        <div className="div">
          We offer a range of image processing services for drone-captured
          images, including orthoimages, panoramic images, and other formats.
          Additionally, we assist our clients in collecting and processing data
          for AI learning purposes, helping to improve their AI-based
          applications and services
        </div>
      </>
    ),

    img: "/assets/business/dron/dron-icon3.svg",
  },

  {
    title: "비행교육 및 AS",
    engtitle: "Solution Training & After-sales Service",
    sub: (
      <>
        <div className="dot"></div>
        <div>
          드론 솔루션을 활용한 드론 운용에 대한 교육과 안정적
          <br />인 AS를 통해 고객 여러분의 효율적인 시스템 운영을 지원합니다.
        </div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div className="div">
          Training systems and follow-up support are provided for users so that
          they can easily and efficiently operate the system
        </div>
      </>
    ),

    img: "/assets/business/dron/dron-icon4.svg",
  },
];
