import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../common/common.inerface";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import MainNews from "../../components/MainNews/MainNews";

type Props = {
  userAgent: UserAgentType;
  data: any;
};
const Main = ({ userAgent, data }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  const navigate = useNavigate();
  return (
    <main className="Main">
      <div className="main-section">
        <div className="title-section">
          <div className="title gmarket">
            EXPERIENCE <br /> THE RENOVATIVE WORLD
            <br /> OF DIGITAL TWIN, <br />
            iGiS DRONE PLATFORM
          </div>
          <div className="sub">
            {lang === "kor"
              ? `변화와 혁신을 선도하는 기업, IGIS 입니다`
              : `iGiS, Leads Change and Innovation`}
          </div>

          <div className="title-btn">
            <button
              className="detail-nav"
              onClick={() => {
                navigate("/company");
              }}
            >
              {lang === "kor" ? "소개 바로가기" : "About Us"}
              <img
                src="/assets/common/detail-arrow.svg"
                alt=""
                className="arrow"
              />
            </button>
          </div>
        </div>

        <div className="video-section">
          <video autoPlay loop muted playsInline preload="auto">
            <source src="/video/dron.webm" type="video/webm" />
            <source src="/video/dron.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="gif-text-section">
          <div className="bubble1"></div>
          <div className="bubble2"></div>
          <div className="gif-section">
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              className="gif"
            >
              <source src="/video/icon1.webm" type="video/webm" />
              <source src="/video/icon-1.mp4" type="video/mp4" />
            </video>
            <div className="title">iGiS, where quality meets expertise</div>
            <div className="sub">
              {lang === "kor" ? (
                <>
                  {" "}
                  IGIS는 ICT 전문기업으로 공간정보 솔루션과 드론 솔루션을
                  <br /> 개발하여 공공 및 민간에 솔루션을 제공하고 있습니다
                </>
              ) : (
                <>
                  We are an ICT-specialized company that develops and
                  <br /> provides drone and geospatial information solutions to
                  <br />
                  the public and private sectors
                </>
              )}
            </div>
          </div>

          <div className="gif-section">
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              className="gif"
            >
              <source src="/video/icon-2.webm" type="video/webm" />
              <source src="/video/icon-2.mp4" type="video/mp4" />
            </video>
            <div className="title">Our confidence drives our success</div>
            <div className="sub">
              {lang === "kor"
                ? "우리는 성공에 대한 확신을 현실로 만들고 있습니다"
                : "We are making our belief in achieving success a reality"}
            </div>
          </div>
        </div>
        <div className="business-section">
          <div className="title-wrapper">
            <div className="title">
              {lang === "kor"
                ? "IGIS의 사업분야를 소개합니다"
                : "We excel in these fields"}
            </div>
            <div className="sub">
              {lang === "kor" ? (
                <>
                  IT 분야의 전문 기술력과 산업에 대한 통찰력을 기반으로 글로벌{" "}
                  <br />
                  선도기업으로 도약해 나가겠습니다
                </>
              ) : (
                <>
                  With our IT expertise and industry insight, <br /> we aim to
                  satisfy clients and strive for <br /> global leadership.
                </>
              )}
            </div>
          </div>
          <div className="box-wrapper">
            {business.map((item, idx) => {
              return (
                <div key={idx} className={`box ${item.type}`}>
                  <div className="title">
                    {lang === "kor" ? item.title : item.engtitle}
                  </div>
                  <div className="sub">
                    {lang === "kor" ? item.sub : item.engsub}
                  </div>
                  <div className="img">
                    <img src={item.img} alt="" />
                  </div>

                  <button
                    className="detail-nav"
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    {lang === "kor" ? "자세히 보기" : "View details"}
                    <img
                      src="/assets/common/detail-arrow.svg"
                      alt=""
                      className="arrow"
                    />
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="solution-section">
          <div className="bubble3"></div>
          <div className="bubble4"></div>
          <div className="bubble5"></div>
          <div className="title-wrapper">
            <div className="title">
              {lang === "kor" ? (
                <>
                  {" "}
                  드론 기술의 노하우를 바탕으로 <br />
                  다양한 분야에 적용이 <br className="mb" />
                  가능한 범용 솔루션
                </>
              ) : (
                <>
                  Smart general-purpose drone solution <br /> created based on
                  our technology expertise
                </>
              )}
            </div>
            <div className="sub">
              {lang === "kor" ? (
                <>
                  재난 재해 분야, 건설,토목, 환경, 측량 등 <br />
                  다양한 분야에 적용이 가능한 범용 IGIS솔루션
                </>
              ) : (
                <>
                  Try utilizing it in various fields such as disaster
                  management, <br className="pc tablet" /> construction, civil
                  engineering, environment, surveying, etc.
                </>
              )}
            </div>
          </div>
          <div className="box-wrapper">
            {solution.map((item, idx) => {
              return (
                <div key={idx} className={`box ${item.type}`}>
                  <div className="title">{item.title}</div>
                  <div className="sub">
                    {lang === "kor" ? item.sub : item.eng}
                  </div>

                  {item.type === "bottom" ? (
                    <div className="bottom-wrapper">
                      <div className="icon">{item.icon}</div>
                      <div className="icon1 icon">{item.icon1}</div>
                      <div className="icon2 icon">{item.icon2}</div>
                      <div className="icon3 icon">{item.icon3}</div>
                      <div className="icon4 icon">{item.icon4}</div>
                      <div className="icon5 icon">{item.icon5}</div>
                      <div className="icon6 icon">{item.icon6}</div>
                      <div className="img">
                        <img src={item.img} alt="" />
                      </div>
                    </div>
                  ) : (
                    <div className="top-wrapper">
                      <div className="icon">{item.icon}</div>
                      <div className="icon1 icon">{item.icon1}</div>
                      <div className="icon2 icon">{item.icon2}</div>
                      <div className="img">
                        <img src={item.img} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="partner-section">
          <div className="title-wrapper">
            <div className="title">Our Clientele</div>
            <div className="sub">
              {lang === "kor"
                ? "IGIS와 함께하는 파트너를 소개합니다"
                : "Here are our valued clients"}
            </div>
          </div>

          <div className="line">
            <img src={`/assets/main/${userAgent}/line.svg`} alt="" />
          </div>

          <div className="img-wrapper">
            <img
              src={`/assets/main/${userAgent}/partner.png`}
              srcSet={`/assets/main/${userAgent}/partner@2x.png 2x, /assets/main/${userAgent}/partner@3x.png 3x`}
            />
          </div>

          <div className="line2">
            <img src={`/assets/main/${userAgent}/line.svg`} alt="" />
          </div>
        </div>
        <MainNews lang={lang} navigate={navigate} />
        <div className="service-section">
          <div className="title-wrapper">
            <div className="title">IGIS SERVICE</div>
            <div className="sub">
              {lang === "kor"
                ? "IGIS에서 제공하는 서비스"
                : `We provide high-quality
 services for you`}
            </div>
          </div>

          <div className="box-wrapper">
            {service.map((item, idx) => {
              return (
                <div key={idx} className="box">
                  <div className="title">
                    {lang === "kor" ? item.title : item.eng}
                  </div>
                  <button
                    className="button"
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    {lang === "kor" ? item.button : item.engbtn}
                  </button>
                  <div className="img">
                    <img src={item.img} alt="로고" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="bottom-img">
          <img
            alt=""
            src="/assets/main/pc/main-bottom.png"
            srcSet="/assets/main/pc/main-bottom@2x.png 2x, /assets/main/pc/main-bottom@3x.png 3x"
          />
        </div>
      </div>
    </main>
  );
};

export default Main;

const business = [
  {
    title: "DFOS 드론 플랫폼",
    engtitle: "DFOS Drone Platform",
    sub: (
      <>
        더욱 편리한 드론 경험을 위한 통합 드론
        <br /> 소프트웨어 플랫폼
      </>
    ),
    engsub: (
      <>
        Drone software platform for a more convenient drone experience through
        user-friendly functions
      </>
    ),
    link: "/business/dron",

    img: "/assets/main/pc/main-icon2.svg",
  },

  {
    title: "GIS 시스템 ",
    engtitle: "GIS",

    sub: (
      <>
        고객 맞춤형 지리정보 시스템을 구축하여 <br />
        최적의 솔루션 및 서비스를 제공
      </>
    ),
    engsub: (
      <>
        We build customized GIS solutions to provide optimal solutions and
        services for our clients
      </>
    ),
    link: "/business",
    type: "magin",
    img: "/assets/main/pc/main-icon1.svg",
  },

  {
    title: "FMS 시스템",
    engtitle: "FMS",
    sub: (
      <>
        지리정보 기반으로 현장업무를 지원하는 <br />
        APP으로 다양한 현장에 적용해 <br />
        현장 업무의 생산성 향상이 가능
      </>
    ),
    engsub: (
      <>
        Through the FMS app, we support field work based on geospatial
        information to enhance productivity
      </>
    ),
    link: "/business/fms",
    img: "/assets/main/pc/main-icon3.svg",
  },

  {
    title: "R&D 및 용역 시스템 ",
    engtitle: "FMS",
    sub: (
      <>
        기업의 역량 강화와 매출신장을 위해 <br />
        다양한 과제사업을 진행
      </>
    ),
    engsub: (
      <>
        We perform various R&D projects to strengthen the company's capabilities
        and increase revenue
      </>
    ),
    link: "/business/service",
    type: "magin",
    img: "/assets/main/pc/main-icon4.svg",
  },
];

const solution = [
  {
    title: "Drone Solution",
    sub: "드론을 활용하여 각종 업무에 보다 효율적으로 대응할 수 있는 드론 솔루션",
    eng: "Solutions to improve work efficiency using drones",
    icon: "Pilot",
    icon1: "Panorama",
    icon2: "IMS",
    icon4: "Control System",
    icon5: "Streaming Viewer",
    icon3: "Viewer",
    icon6: "Weather",
    type: "bottom",
    img: "/assets/main/pc/solution2.svg",
  },

  {
    title: "GIS SOLUTION",
    sub: "GIS 솔루션으로 다양한 문제를 해결 할 수 있습니다",
    eng: "Solutions for utilizing geospatial data in business operations",
    icon: "IGIS",
    icon1: "IGIS Forest",
    icon2: "FMS",
    img: "/assets/main/pc/solution1.svg",
  },
];

const service = [
  {
    title: (
      <>
        더욱 편리한 <br className="mb" /> 드론 경험을 위한 <br className="pc" />
        드론 <br className="mb" /> 소프트웨어 플랫폼 DFOS
      </>
    ),
    eng: (
      <>
        Drone software platform for a more <br className="pc tablet" />{" "}
        convenient drone experience
      </>
    ),
    button: "디포스 SHOP 바로가기",
    engbtn: "DFOS shop",
    link: "/contactus",
    img: "/assets/common/dfos-logo.svg",
  },

  {
    title: (
      <>
        IGIS와 함께 더 넓은 <br />
        세상을 만나보세요
      </>
    ),
    eng: <>Discover a wider world with iGiS</>,
    button: "고객 문의 바로가기",
    engbtn: "Inquiry",
    link: "/contactus",

    img: "/assets/common/logo.svg",
  },
];
