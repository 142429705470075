import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import {
  NewsInterface,
  UserAgentType,
} from "../../../../../../common/common.inerface";
import VideoDetail from "../VideoDetail";
import { useLocation } from "react-router-dom";
import { db } from "../../../../../../config/firebase";

type Props = {
  userAgent: UserAgentType;
};
const VideoDetailContainer = ({ userAgent }: Props) => {
  const id = useLocation().pathname.split("/")[4];
  const [DetailData, setDetailData] = useState<NewsInterface | undefined>(
    () => undefined
  );
  const [Content, setContent] = useState<string>("");

  useEffect(() => {
    getDoc(doc(db, "video", id)).then((snapshot) => {
      const value = snapshot.data() as NewsInterface;
      setDetailData(value);
      if (value.content) {
        getDoc(doc(db, "detail", value.content)).then((res) => {
          const data = res.data();
          if (data) {
            setContent(data.content);
          }
        });
      }
    });

    return () => {};
  }, [id]);

  if (DetailData) {
    return <VideoDetail data={DetailData} content={Content} />;
  }
  return <></>;
};

export default VideoDetailContainer;
