import React, { useCallback, useState } from "react";
import "./css/index.css";
import Map from "../../components/map/map";
import { UserAgentType } from "../../common/common.inerface";
import { RootState } from "../../reducer";
import { useSelector } from "react-redux";
import InfoInput from "../InfoInput/InfoInput";
import { ContactInter } from "../../lib/reducer";
import ContactButton from "../ContactButton/ContactButton";
import FileUploader from "../FileUploader/FileUploader";
import Loading from "../Loading/Loading";

type Props = {
  userAgent: UserAgentType;
  data: ContactInter;
  Update: (type: string, value: any) => void;
  category: string;
  SaveContact: () => void;
  title: string;
};
const Contact = ({ data, Update, SaveContact, title, category }: Props) => {
  const { isAgree } = data;
  const isValid = useCallback(() => {
    const { phone, company, email, manager, rank } = data;
    return phone && company && email && manager && rank ? true : false;
  }, [data]);

  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );

  return (
    <div className="contact">
      <div className="wrapper">
        {category === "customer" && (
          <div className="left">
            <img src="/assets/header/logo.svg" />
            <div className="title">
              {lang === "kor" ? (
                <>
                  궁금한 점이 있다면 <br />
                  문의해주세요
                </>
              ) : (
                <>
                  Have any questions? <br />
                  Contact us!
                </>
              )}
            </div>
          </div>
        )}

        <div className="right">
          <div className="title-wrapper">{title}</div>
          {category === "customer" && (
            <div className="right-input">
              <InfoInput
                engtitle="Company name"
                title="회사명"
                data={data.company}
                catagory="company"
                type="input"
                Update={Update}
                placeholder="내용 입력"
                engplaceholder="Enter your name"
              />

              <InfoInput
                engtitle="Contact Information"
                title="연락처"
                data={data.phone}
                catagory="phone"
                type="input"
                Update={Update}
                placeholder="숫자만 입력"
                engplaceholder="Enter your contact information"
                inputType="number"
              />

              <InfoInput
                engtitle="Name of person in charge"
                title="담당자 성명"
                data={data.manager}
                catagory="manager"
                type="input"
                Update={Update}
                placeholder="내용 입력"
                engplaceholder="Enter your content"
              />

              <InfoInput
                engtitle="Job position of person in charge"
                title="담당자 직급"
                data={data.rank}
                catagory="rank"
                type="input"
                Update={Update}
                placeholder="내용 입력"
                engplaceholder="Enter your content"
              />

              <InfoInput
                engtitle="Email"
                title="이메일"
                data={data.email}
                catagory="email"
                type="input"
                Update={Update}
                placeholder="이메일을 입력해주세요"
                engplaceholder="Enter your email"
              />
            </div>
          )}

          {category === "contact" && (
            <div className="contact-input">
              <ContactButton
                title={lang === "kor" ? "문의 분야" : "Field of inquiry"}
                data={data.selectedButton}
                Update={Update}
                lang={lang}
                type="contact"
              />

              <ContactButton
                title={lang === "kor" ? "제품 선택" : "Product Selection"}
                data={data.selectedProductButton}
                Update={Update}
                lang={lang}
                type="product"
              />

              <InfoInput
                engtitle="Purpose of use"
                title="사용 용도"
                data={data.use}
                catagory="use"
                type="input"
                Update={Update}
                placeholder="내용 입력"
                engplaceholder="Enter your content"
              />

              <InfoInput
                engtitle="Inquiry Contents"
                title="문의 내용"
                data={data.text}
                catagory="text"
                type=""
                Update={Update}
                placeholder="문의 내용을 입력해주세요"
                engplaceholder="Enter your content"
              />
              {file_layouts.map((item, idx) => {
                return (
                  <FileUploader
                    title={
                      lang === "kor"
                        ? `첨부파일 ${idx + 1}`
                        : `Attached file ${idx + 1}`
                    }
                    Update={Update}
                    data={data.fileData[idx]}
                    key={idx}
                    index={idx}
                    lang={lang}
                  />
                );
              })}
            </div>
          )}

          {category === "agreement" && (
            <>
              {" "}
              <div className="agreement-text">
                <div className="text">
                  {lang === "kor"
                    ? `개인정보취급방침1. 개인정보의 수집 및 이용 목적가맹 관련 문의
                  확인 및 답변을 위한 연락통지, 처리결과 통보 등을 목적으로
                  개인정보를 처리합니다.2. 처리하는 개인정보 항목필수항목 :
                  회사명, 연락처, 이메일 (접속 IP 정보, 쿠키, 서비스 이용 기록,
                  접속 로그)선택항목 : 담당자 성명, 담당자 직급3. 개인정보의
                  처리 및 보유 기간① 법령에 따른 개인정보 보유.이용기간 또는
                  정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유,
                  이용기간 내에서 개인정보를 처리, 보유합니다.② 개인정보의 보유
                  기간은 5년입니다.`
                    : `Privacy Statement
1. Purpose of collection and use of personal information Personal information is processed for the purpose of contact notification for confirmation and response of affiliate-related inquiries and notification of processing results.

2. Personal information items to be processed
Required items: company name, contact information, e-mail (access IP information, cookies, service usage records, access log) Optional items: name of person in charge, position in charge

3. Personal information processing and retention period
① Personal information retention and use in accordance with laws and regulations, or the personal information agreed upon when collecting personal information from the data subject, processing and retaining personal information within the period of use.
② The retention period of personal information is 5 years.`}
                </div>
              </div>
              <button
                className={`agreement-btn ${isAgree && "check-btn"}`}
                onClick={() => {
                  Update("agree", !isAgree);
                }}
              >
                <div className={`left ${isAgree && "left-check"}`}>
                  {isAgree && (
                    <>
                      <img src="/assets/common/check.svg" alt="" />
                    </>
                  )}
                </div>
                <div className={`right ${lang === "eng" && "right-eng"}`}>
                  {lang === "kor" ? (
                    <> 상담을 위한 개인정보 사용에 동의합니다</>
                  ) : (
                    <>
                      I agree to the use of my personal <br className="mb" />{" "}
                      information for the purpose of inquiry.
                    </>
                  )}
                </div>
              </button>
              <button
                className={`btn ${(!isAgree || !isValid()) && "is-check-btn"}`}
                disabled={!isAgree}
                onClick={() => {
                  if (isAgree && isValid()) {
                    SaveContact();
                  }
                }}
              >
                {lang === "kor" ? "문의하기" : "Send"}
              </button>
              <div className="btn-text">
                {lang === "kor"
                  ? "문의를 남겨주시면 담당자가 확인 후 연락을 드립니다."
                  : "We will check your message and get back to you soon."}
              </div>
            </>
          )}
        </div>
      </div>
      <Loading />
    </div>
  );
};

export default Contact;

let file_layouts = ["첨부파일 1", "첨부파일 2", "첨부파일 3"];
