import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import Solution from "../../../../components/Solution/Solution";

type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const Ims = ({ userAgent, lang }: Props) => {
  return (
    <main className="ims">
      <Solution
        lang={lang}
        gradationType="imscircle"
        box_titles={box_titles}
        gradation={false}
        type="ims"
        box_layouts={box_layouts}
        userAgent={userAgent}
        src="/assets/solution/dron/ims/pc/ims-img.png"
        srcSet="/assets/solution/dron/ims/pc/ims-img@2x.png 2x, /assets/solution/dron/ims/pc/ims-img@3x.png 3x"
      />
    </main>
  );
};

export default Ims;

const box_layouts = [
  {
    point: "Point 01",
    title: <>편리한 자동 정사영상 제작</>,
    engtitle: (
      <>
        Effortless automatic <br />
        orthophoto mapping
      </>
    ),

    sub: (
      <>
        <div className="dot"></div>
        <div>
          촬영된 낱장사진을 클릭 몇 번을 통해 <br />
          간단하게 정사영상으로 제작이 가능하며,
          <br /> 그 처리 과정을 실시간으로 어디서나 확인이 가능합니다.{" "}
        </div>
      </>
    ),
    engsub: (
      <>
        <div className="dot"></div>
        <div>
          With just a few clicks, <br className="tablet" />
          drone photos can be easily
          <br className="pc" /> transformed into <br className="tablet" />{" "}
          accurate orthophoto maps,
          <br className="pc" /> and the processing <br className="tablet" />{" "}
          progress can be monitored in real-time.
        </div>
      </>
    ),

    src: "/assets/solution/dron/ims/pc/ims-boximg1.png",
    srcSet:
      "/assets/solution/dron/ims/pc/ims-boximg1@2x.png 2x, /assets/solution/dron/ims/pc/ims-boximg1@3x.png 3x",
  },

  {
    point: "Point 02",
    title: <>2D·3D 영상서비스를 제공</>,
    engtitle: <>2D·3D Supported</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>
          자동정사영상제작 기능으로 제작된 정상영상의 경우는
          <br /> 정사영상에 원본 낱장사진과 바로 연결된 마커들이 <br /> 표시되어
          마커를 클릭하면 원본사진을 바로 확인이 가능합니다.
        </div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>
          The automatically generated <br className="tablet" />
          orthophoto maps
          <br className="pc" /> feature markers that are{" "}
          <br className="tablet" />
          linked to the original photos,
          <br className="pc" />
          allowing users to quickly access <br className="pc" />
          them with just a click <br className="tablet" /> on the corresponding
          marker.
        </div>
      </>
    ),

    src: "/assets/solution/dron/ims/pc/ims-boximg2.png",
    srcSet:
      "/assets/solution/dron/ims/pc/ims-boximg2@2x.png 2x, /assets/solution/dron/ims/pc/ims-boximg2@3x.png 3x",

    type: "reverse",
  },

  {
    point: "Point 03",
    title: <>통계 기능 제공</>,
    engtitle: <>Statistical Features</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>
          비행 완료한 기체별 비행시간, 비행면적, 비행거리 등의 <br />
          다양한 정보확인과 통계기능을 지원하여 <br />
          편리한 업무환경을 제공합니다.
        </div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>
          Users can view various information <br className="tablet" />
          about each drone,
          <br className="pc" /> such as flight time,
          <br className="tablet" /> area covered, distance traveled, etc,{" "}
          <br className="pc" /> and the <br className="tablet" />
          system provides statistics based on this data.
        </div>
      </>
    ),

    src: "/assets/solution/dron/ims/pc/ims-boximg3.png",
    srcSet:
      "/assets/solution/dron/ims/pc/ims-boximg3@2x.png 2x, /assets/solution/dron/ims/pc/ims-boximg3@3x.png 3x",
  },
];

const box_titles = [
  {
    title: <>DFOS IMS</>,

    engtitle: <>DFOS IMS</>,
    sub: (
      <>
        드론으로 확보한 대량 · 고용량의 영상을 등록 · 관리 <br />
        분석 · 활용하기 위한 시스템으로 <br /> 신속하고 효율적인 업무 체계의
        확립이 가능한 서비스
      </>
    ),

    engsub: (
      <>
        <div>
          Streamline your management <br />
          rand analysis of large-capacity drone images
          <br className="pc" />
          <br className="tablet" /> with our comprehensive system, DFOS IMS
        </div>
      </>
    ),
  },
];
