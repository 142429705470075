import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import Solution from "../../../../components/Solution/Solution";

type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const ControlSystem = ({ userAgent, lang }: Props) => {
  return (
    <main className="ControlSystem">
      <Solution
        lang={lang}
        gradationType="controlcircle"
        box_titles={box_titles}
        gradation={false}
        type="control"
        box_layouts={box_layouts}
        userAgent={userAgent}
        src="/assets/solution/dron/controlsystem/pc/control-img.png"
        srcSet="/assets/solution/dron/controlsystem/pc/control-img@2x.png 2x, /assets/solution/dron/controlsystem/pc/control-img@3x.png 3x"
      />
    </main>
  );
};

export default ControlSystem;

const box_layouts = [
  {
    point: "Point 01",
    title: <>미션 생성</>,
    engtitle: <>Create Missions</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>Way Point Mission </div>
      </>
    ),
    engsub: (
      <>
        <div className="dot"></div>
        <div>Way Point Mission </div>
      </>
    ),

    sub1: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Mission</div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Mission</div>
      </>
    ),

    sub2: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Mission</div>
      </>
    ),

    engsub2: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Mission</div>
      </>
    ),

    sub3: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Distance Interval Mission</div>
      </>
    ),

    engsub3: (
      <>
        <div className="dot"></div>
        <div className="div"> Grid/Polygon Distance Interval Mission</div>
      </>
    ),

    sub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>미션 공간자료(KML, SHP) 다운로드</div>
      </>
    ),

    engsub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Download mission geospatial data (KML, SHP)</div>
      </>
    ),

    engsub5: (
      <>
        <div className="dot"></div>
        Functions to make automated flight
        <br /> missions based on geospatial data
        <br /> (KML, SHP)
      </>
    ),
    sub5: (
      <>
        {" "}
        <div className="dot"></div>
        <div>
          공간자료(KML, SHP) 기반 <br className="mb" />
          자동 비행 미션 생성 기능
        </div>
      </>
    ),
    src: "/assets/solution/dron/controlsystem/pc/control-boximg1.png",
    srcSet:
      "/assets/solution/dron/controlsystem/pc/control-boximg1@2x.png 2x, /assets/solution/dron/controlsystem/pc/control-boximg1@3x.png 3x",
  },

  {
    point: "Point 02",
    title: <>통계 기능</>,
    engtitle: <>Statistical Functionality</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>Flight Time/Distance/ Area </div>
      </>
    ),

    sub1: (
      <>
        <div className="dot"></div>
        <div>Streaming List 및 다운로드 </div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div>Streaming list and download</div>
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>Flight Record
      </>
    ),

    sub3: (
      <>
        {" "}
        <div className="dot"></div>날씨, 풍향, 풍속, 자기장 정보 표출
      </>
    ),

    engsub3: (
      <>
        {" "}
        <div className="dot"></div>
        <div>
          Weather, wind direction, wind speed, magnetic field info display
        </div>
      </>
    ),

    sub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>주소 표시 기능</div>
      </>
    ),

    engsub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Address display function</div>
      </>
    ),

    sub5: (
      <>
        {" "}
        <div className="dot"></div>
        <div>비행 시간</div>
      </>
    ),

    engsub5: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Flight duration</div>
      </>
    ),

    sub6: (
      <>
        {" "}
        <div className="dot"></div>
        <div>비행 경로 공간자료 다운로드 (KML, SHP)</div>
      </>
    ),

    engsub6: (
      <>
        {" "}
        <div className="dot"></div>
        <div>Download geospatial data (KML, SHP) of a flight route</div>
      </>
    ),

    src: "/assets/solution/dron/controlsystem/pc/control-boximg2.png",
    srcSet:
      "/assets/solution/dron/controlsystem/pc/control-boximg2@2x.png 2x, /assets/solution/dron/controlsystem/pc/control-boximg2@3x.png 3x",

    type: "reverse",
  },

  {
    point: "Point 03",
    title: <>드론 정보 및 영상 스트리밍</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>드론 기체 정보</div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>Drone aircraft info</div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div>Drone GPS info (longitude and latitude coordinates)</div>
      </>
    ),

    engsub2: (
      <>
        <div className="dot"></div>
        <div>attery info</div>
      </>
    ),

    engsub3: (
      <>
        <div className="dot"></div>
        <div>Drone status info</div>
      </>
    ),

    engsub4: (
      <>
        <div className="dot"></div>
        <div>Real-time streaming of drone footage</div>
      </>
    ),
    engtitle: <>Drone Information Display & Video Streaming</>,

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        드론 GPS정보(경위도 좌표)
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>배터리 정보
      </>
    ),

    sub3: (
      <>
        {" "}
        <div className="dot"></div>드론 상태 정보 등
      </>
    ),

    sub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>드론 촬영 영상 실시간 스트리밍</div>
      </>
    ),

    sub5: (
      <>
        {" "}
        <div className="dot"></div>
        <div>
          공간자료(KML, SHP) 기반 <br className="mb" />
          자동 비행 미션 생성 기능
        </div>
      </>
    ),
    src: "/assets/solution/dron/controlsystem/pc/control-boximg3.png",
    srcSet:
      "/assets/solution/dron/controlsystem/pc/control-boximg3@2x.png 2x, /assets/solution/dron/controlsystem/pc/control-boximg3@3x.png 3x",
  },

  {
    point: "Point 04",
    title: (
      <>
        Drone Image Live Map and BackgroundM
        <br /> Map Function
      </>
    ),

    sub: (
      <>
        <div className="dot"></div>
        <div>드론 사진 LIVE MAP 표출</div>
      </>
    ),
    engsub: (
      <>
        <div className="dot"></div>
        <div>Display drone photos on a live map</div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div>Download live map</div>
      </>
    ),

    engsub2: (
      <>
        <div className="dot"></div>
        <div>
          {" "}
          Various background maps supported including <br /> Google Maps
        </div>
      </>
    ),

    engtitle: <>Drone Image Live Map and Background Map Function</>,

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        LIVE MAP 다운로드
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>Google Naver 등 다양한 배경 지도 기능
      </>
    ),

    src: "/assets/solution/dron/controlsystem/pc/control-boximg4.png",
    srcSet:
      "/assets/solution/dron/controlsystem/pc/control-boximg4@2x.png 2x, /assets/solution/dron/controlsystem/pc/control-boximg4@3x.png 3x",

    type: "reverse",
  },

  {
    point: "Point 05",
    title: <>기체 설정 및 원격 제어</>,
    engtitle: <>Drone Setting and Remote Control</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>기체 자동 인식 기능 </div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>Automatic aircraft recognition function </div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div>Set/Change aircraft name</div>
      </>
    ),

    engsub2: (
      <>
        <div className="dot"></div>
        <div>Take Off, Landing</div>
      </>
    ),

    engsub3: (
      <>
        <div className="dot"></div>
        <div>Start Flight, Pause Flight, End Flight, Return Home</div>
      </>
    ),

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div"> 기체 이름 설정 및 변경</div>
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>Take Off, Landing
      </>
    ),

    sub3: (
      <>
        {" "}
        <div className="dot"></div>비행 시작, 일시정지, 비행종료, 홈 복귀
      </>
    ),

    src: "/assets/solution/dron/controlsystem/pc/control-boximg5.png",
    srcSet:
      "/assets/solution/dron/controlsystem/pc/control-boximg5@2x.png 2x, /assets/solution/dron/controlsystem/pc/control-boximg5@3x.png 3x",
  },
];

const box_titles = [
  {
    title: (
      <>
        DFOS <br />
        Control system(Web)
      </>
    ),

    engtitle: (
      <>
        DFOS <br />
        Control system(Web)
      </>
    ),
    sub: (
      <>
        드론 사진, 동영상, 촬영 및 경로 비행에 대해 <br />
        편리하게 컨트롤을 할 수 있는 APP
      </>
    ),

    engsub: (
      <>
        <div>
          A web service that enables users to manage
          <br className="pc" /> drone bigdata conveniently
          <br /> through remote and real-time control,
          <br /> verification, and video streaming.
        </div>
      </>
    ),
  },
];
