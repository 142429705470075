import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import { HistoryType } from "../../../../routes/CompanyRoute/CompanyRoute";
type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
  data: HistoryType[];
};
const CompanyHistory = ({ userAgent, lang, data }: Props) => {
  return (
    <main className="CompanyHistory">
      <div className="main-section">
        <div className="gradation-section"></div>
        <div className="title-section">
          <div className="title">
            {lang === "kor"
              ? "사업 영역을 확장해나가는 IGIS "
              : "Pioneering new horizons"}
          </div>
          <div className="sub">HISTORY</div>
        </div>
        <div className="history-content-section">
          {data.map(({ year, content, eng }, idx) => {
            return (
              <div className="history-box" key={idx}>
                <div className="left-line">
                  <div className="circle" />
                  <div className="line" />
                </div>
                <div className="right-content">
                  <div className="year">{year}</div>
                  <div className="content">
                    {lang === "kor" ? content : eng}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default CompanyHistory;
