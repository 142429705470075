import { Route, Routes } from "react-router-dom";
import TopNavSection from "../../components/TopNavSection/TopNavSection";
import { UserAgentType } from "../../common/common.inerface";
import "./css/index.css";
import NewsContainer from "../../page/Company/CompanyNews/CompanyNews/container/NewsContainer";
import NewsDetailContainer from "../../page/Company/CompanyNews/CompanyNews/NewsDetail/container/NewsDetailContainer";
import VideoContainer from "../../page/Company/CompanyNews/CompanyVideo/container/VideoContainer";
import VideoDetailContainer from "../../page/Company/CompanyNews/CompanyVideo/VideoDetail/container/VideoDetailContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
type Props = {
  userAgent: UserAgentType;
};

const CompanyNewsRoutes = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  return (
    <main className="company-page">
      <div className="title-section">
        <div className="left">
          <div className="title">IGIS NEWS</div>
          <div className="subtitle">
            {lang === "kor" ? "뉴스 및 홍보영상" : "NEWS & MEDIA"}
          </div>
        </div>
        <div className="right">
          <div className="gif">
            {" "}
            <video autoPlay loop muted playsInline preload="auto">
              <source src="/video/company.webm" type="video/webm" />
              <source src="/video/company.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <TopNavSection
        nav_layouts={nav_layouts}
        page_type="company"
        userAgent={userAgent}
        lang={lang}
      />
      <Routes>
        <Route path="/" element={<NewsContainer userAgent={userAgent} />} />
        <Route
          path="video"
          element={<VideoContainer userAgent={userAgent} />}
        />
        <Route
          path="video/detail/*"
          element={<VideoDetailContainer userAgent={userAgent} />}
        />
        <Route
          path="detail/*"
          element={<NewsDetailContainer userAgent={userAgent} />}
        />
      </Routes>
    </main>
  );
};

export default CompanyNewsRoutes;

const nav_layouts = [
  {
    title: "뉴스",
    eng: "News",
    link: "/news",
  },
  {
    title: "홍보영상",
    eng: "Media",
    link: "/news/video",
  },
];
