const contact = ["제품문의", "라이선스 문의", "기타 문의"];
const product = ["GIS Solution", "Drone Solution", "기타 문의"];

export const MailContent = (obj: any) => {
    return `
        <div style="width:100%; height:100%;">
            <div style="width:100%; padding:10px; border-bottom: 1px solid;">
                <strong style="font-size: 1.1rem;">
                    고객 정보
                </strong>
            </div>
            <div style="width:100%; padding-left:10px; margin-top:10px; display:flex; align-items:center; column-gap:15px;">
                <div style="min-width:100px;">
                    <strong>
                        회사명
                    </strong>
                </div>
                <div style="word-break:break-all;">
                    ${obj.company}
                </div>
            </div>
            <div style="width:100%; padding-left:10px; margin-top:10px; display:flex; align-items:center; column-gap:15px;">
                <div style="min-width:100px;">
                    <strong>
                        연락처
                    </strong>
                </div>
                <div style="word-break:break-all;">
                    ${obj.phone}
                </div>
            </div>
            <div style="width:100%; padding-left:10px; margin-top:10px; display:flex; align-items:center; column-gap:15px;">
                <div style="min-width:100px;">
                    <strong>
                        담당자성명
                    </strong>
                </div>
                <div style="word-break:break-all;">
                    ${obj.manager}
                </div>
            </div>
            <div style="width:100%; padding-left:10px; margin-top:10px; display:flex; align-items:center; column-gap:15px;">
                <div style="min-width:100px;">
                    <strong>
                        담당자직급
                    </strong>
                </div>
                <div style="word-break:break-all;">
                    ${obj.rank}
                </div>
            </div>
            <div style="width:100%; padding-left:10px; margin-top:10px; display:flex; align-items:center; column-gap:15px;">
                <div style="min-width:100px;">
                    <strong>
                        이메일
                    </strong>
                </div>
                <div style="word-break:break-all;">
                    ${obj.email}
                </div>
            </div>
            <div style="width:100%; padding:10px; margin-top:10px; border-bottom: 1px solid; border-top:1px solid;">
                <strong style="font-size: 1.1rem;">
                    문의 내용
                </strong>
            </div>
            <div style="width:100%; padding-left:10px; margin-top:10px; display:flex; align-items:center; column-gap:15px;">
                <div style="min-width:100px;">
                    <strong>
                        문의 분야
                    </strong>
                </div>
                <div style="word-break:break-all;">
                    ${contact[obj.selectedButton]}
                </div>
            </div>
            <div style="width:100%; padding-left:10px; margin-top:10px; display:flex; align-items:center; column-gap:15px;">
                <div style="min-width:100px;">
                    <strong>
                        제품 선택
                    </strong>
                </div>
                <div style="word-break:break-all;">
                    ${product[obj.selectedProductButton]}
                </div>
            </div>
            <div style="width:100%; padding-left:10px; margin-top:10px; display:flex; align-items:center; column-gap:15px;">
                <div style="min-width:100px;">
                    <strong>
                        사용 용도
                    </strong>
                </div>
                <div style="word-break:break-all;">
                    ${obj.use}
                </div>
            </div>
            <div style="width:100%; padding-left:10px; margin-top:10px; display:flex; align-items:center; column-gap:15px;">
                <div style="min-width:100px;">
                    <strong>
                        문의 내용
                    </strong>
                </div>
                <div style="word-break:break-all;">
                    ${obj.text}
                </div>
            </div>
        </div>
    `;
}