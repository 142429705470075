import { Route, Routes } from "react-router-dom";
import TopNavSection from "../../components/TopNavSection/TopNavSection";
import { UserAgentType } from "../../common/common.inerface";
import "./css/index.css";
import AwardDeed from "../../page/Company/CompanyAward/AwardDeed/AwardDeed";
import AwardProperty from "../../page/Company/CompanyAward/AwardProperty/AwardProperty";
import AwardList from "../../page/Company/CompanyAward/AwardList/AwardList";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
type Props = {
  userAgent: UserAgentType;
};

const CompanyAwardRoutes = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  return (
    <main className="company-page">
      <div className="title-section">
        <div className="left">
          <div className="title">AWARD</div>
          <div className="subtitle">
            {lang === "kor" ? "인증 및 수상" : "Certification and Awards"}
          </div>
        </div>

        <div className="right">
          <div className="gif">
            <video autoPlay loop muted playsInline preload="auto">
              <source src="/video/company.webm" type="video/webm" />
              <source src="/video/company.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <TopNavSection
        nav_layouts={nav_layouts}
        lang={lang}
        page_type="award"
        userAgent={userAgent}
      />
      <Routes>
        <Route
          path="/"
          element={<AwardDeed userAgent={userAgent} lang={lang} />}
        />
        <Route
          path="property"
          element={<AwardProperty userAgent={userAgent} lang={lang} />}
        />

        <Route
          path="list"
          element={<AwardList userAgent={userAgent} lang={lang} />}
        />
      </Routes>
    </main>
  );
};

export default CompanyAwardRoutes;

const nav_layouts = [
  {
    title: "인증서",
    eng: "Certificate",
    link: "/award",
  },
  {
    title: "지적 재산권",
    eng: "IP",
    link: "/award/property",
  },

  {
    title: "수상현황",
    eng: "Award History",
    link: "/award/list",
  },
];
