import React, { useCallback } from "react";
import "./css/index.css";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";

type Props = {
  title: string;
  engtitle: string;
  type: string;
  data: any;
  placeholder: string;
  engplaceholder: string;
  catagory: string;
  Update: (type: string, value: string) => void;
  inputType?: string;
};

const InfoInput = ({
  title,
  data,
  Update,
  catagory,
  type,
  placeholder,
  inputType,
  engtitle,
  engplaceholder,
}: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  const handleChange = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const newValue = e.target.value;
      Update(catagory, newValue);
    },
    [Update, catagory]
  );

  return (
    <>
      {type === "input" ? (
        <div className="input-section">
          <div className="title">{lang === "kor" ? title : engtitle}</div>
          <input
            value={data}
            type={inputType ? inputType : "text"}
            placeholder={lang === "kor" ? placeholder : engplaceholder}
            onChange={handleChange}
          />
        </div>
      ) : (
        <div className="input-section">
          <div className="title">{lang === "kor" ? title : engtitle}</div>
          <textarea
            onChange={handleChange}
            value={data}
            placeholder={lang === "kor" ? placeholder : engplaceholder}
          />
        </div>
      )}
    </>
  );
};

export default InfoInput;
