import React, { useState } from "react";
import "./css/index.css";
import { FileReaderBase64 } from "../../lib/lib";
import { useDispatch } from "react-redux";
import { LoadingChange } from "../../reducer/config";

type Props = {
  title: string;
  Update: (type: string, value: any, index?: number) => void;
  data?: any;
  index: number;
  lang: "kor" | "eng";
};
const FileUploader = ({ title, Update, index, data, lang }: Props) => {
  const dispatch = useDispatch();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    dispatch(LoadingChange(true));
    if (file) {
      FileReaderBase64(file, file?.name)
        .then((res) => {
          Update("fileData", res, index);
          dispatch(LoadingChange(false));
        })
        .catch(() => {
          dispatch(LoadingChange(false));
        });
    } else {
      dispatch(LoadingChange(false));
    }
  };

  return (
    <>
      <form className="file-uploader">
        <div className="title">{title}</div>
        <div className="input-wrapper">
          <input
            type="text"
            value={data ? data.name : ""}
            placeholder={lang === "kor" ? "파일을 선택해주세요" : "Select file"}
            readOnly
          />
          <label>
            <input type="file" onChange={handleFileChange} />
            {lang === "kor" ? "파일 선택" : "Select file"}
          </label>
        </div>
      </form>
    </>
  );
};

export default FileUploader;
