import {
  NewsInterface,
  UserAgentType,
} from "../../../../../common/common.inerface";
import Screen from "../../../../../components/Screen/Screen";
import { formatDate } from "../../../../../lib/lib";
import "./css/index.css";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  content: string;
  data: NewsInterface;
};

const VideoDetail = ({ content, data }: Props) => {
  const navigate = useNavigate();
  return (
    <main className="VideoDetail">
      <div className="video-section">
        <div className="video-detail">
          <div className="video-title">
            <div className="title">{data.title}</div>
            <div className="date">{formatDate(data.timestamp, "-")}</div>
          </div>
          <Screen data={content} />
        </div>
        <div className="btn">
          <button
            className="list-btn"
            onClick={() => {
              navigate(-1);
            }}
          >
            목록
          </button>
        </div>
      </div>
    </main>
  );
};

export default VideoDetail;
