import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import Solution from "../../../../components/Solution/Solution";

type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const IGIS = ({ userAgent, lang }: Props) => {
  return (
    <main className="IGIS">
      <Solution
        lang={lang}
        gradationType=""
        box_titles={box_titles}
        gradation={true}
        type=""
        box_layouts={box_layouts}
        userAgent={userAgent}
        src="/assets/solution/gis/igis/pc/igis-img.png"
        srcSet="/assets/solution/gis/igis/pc/igis-img@2x.png 2x, /assets/solution/gis/igis/pc/igis-img@3x.png 3x"
      />
    </main>
  );
};

export default IGIS;

const box_layouts = [
  {
    point: "Point 01",
    title: (
      <>
        업무 효율성 향상을 위해
        <br /> 특별히 설계된 최적화된 GIS
      </>
    ),

    engtitle: (
      <>
        Optimized GIS designed specifically to <br className="pc" />
        improve work efficiency
      </>
    ),

    sub: (
      <>
        <div className="dot"></div>
        <div>
          Concurrent License 지원에 따른 여러명의
          <br className="mb" /> 사용자가
          <br className="tablet" /> 추가 <br className="pc" />
          비용 없이 사용 가능
        </div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div className="div">
          Multiple users can use it without additional cost through a concurrent
          license
        </div>
      </>
    ),

    engsub1: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">Intuitive and easy-to-use UI</div>
      </>
    ),
    engsub2: (
      <>
        {" "}
        <div className="dot"></div>Automatic schema editing function
      </>
    ),
    engsub3: (
      <>
        {" "}
        <div className="dot"></div>Additional development support
      </>
    ),
    engsub4: (
      <>
        {" "}
        <div className="dot"></div>Structured editing possible without using CAD
        Map
      </>
    ),

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        한글화 지원으로 쉬운 UI환경 제공
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>스키마 ( Schema ) 자동 수정 기능 제공
      </>
    ),

    sub3: (
      <>
        {" "}
        <div className="dot"></div>추가적인 개발 지원이 가능함
      </>
    ),

    sub4: (
      <>
        {" "}
        <div className="dot"></div>
        <div>
          CAD와의 호환성이 우수하여 복잡한 단계없이 <br className="mb" />
          구조화 편집이 가능 <br className="pc" />
          (CAD Map을 이용하지 않고 <br className="mb" />
          구조화 편집가능)
        </div>
      </>
    ),
    sub5: (
      <>
        {" "}
        <div className="dot"></div>외산 소프트웨어 단속으로 부터 자유로움
      </>
    ),
    src: "/assets/solution/gis/igis/pc/igis-boximg1.png",
    srcSet:
      "/assets/solution/gis/igis/pc/igis-boximg1@2x.png 2x, /assets/solution/gis/igis/pc/igis-boximg1@3x.png 3x",
  },

  {
    point: "Point 02",
    title: (
      <>
        누구나 어디서나 <br />
        쉽고 편리한 공간정보 이용
      </>
    ),
    engtitle: (
      <>Easy and convenient for anyone handling geospatial data anywhere</>
    ),

    sub: (
      <>
        <div className="dot"></div>
        <div>
          공간정보를 이용하고 있는 공공기관 사용가능 <br />
          (국토부, 산림청, 철도청, 지방자치단체 등)
        </div>
      </>
    ),
    engsub: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          Public institutions that utilize geospatial information (e.g. the
          Ministry of Land, Infrastructure and Transport, Korea Forest Service,
          Korea Railroad Corporation, and local governments)
        </div>
      </>
    ),

    sub1: (
      <>
        <div className="dot"></div>
        <div></div>항공사진을 이용하여 수치지형도 제작하는 <br /> 항공측량업체
      </>
    ),

    engsub1: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          Aerial survey companies producing digital maps
        </div>
      </>
    ),
    engsub2: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          Database development companies specializing in structured editing of
          digital maps
        </div>
      </>
    ),
    engsub3: (
      <>
        {" "}
        <div className="dot"></div>
        <div className="div">
          Database development companies specializing in structured editing of
          underground facilities
        </div>
      </>
    ),
    sub2: (
      <>
        {" "}
        <div className="dot"></div>수치지형도를 구조화 편집하는 DB구축 업체
      </>
    ),

    sub3: (
      <>
        {" "}
        <div className="dot"></div>7대 지하시설물을 구조화 편집하는 DB구축 업체
      </>
    ),

    sub4: (
      <>
        CAD와의 호환성이 우수하여 복잡한 단계없이 구조화 편집이 가능 <br />
        (CAD Map을 이용하지 않고 구조화 편집가능)
      </>
    ),
    sub5: <>외산 소프트웨어 단속으로 부터 자유로움</>,

    src: "/assets/solution/gis/igis/pc/igis-boximg2.png",
    srcSet:
      "/assets/solution/gis/igis/pc/igis-boximg2@2x.png 2x, /assets/solution/gis/igis/pc/igis-boximg2@3x.png 3x",
    type: "reverse",
    etc: "subtitle",
    engtype: "eng",
    subtitle1: "공공기관",
    engsubtitle1: "Public",
    subtitle2: "민간업체",
    engsubtitle2: "Private",
  },
];

const box_titles = [
  {
    title: (
      <>
        IGIS <br className="pc" />
        지리정보 솔루션{" "}
      </>
    ),
    engtitle: (
      <>
        Geographic <br /> Information System
      </>
    ),
    sub: (
      <>
        우리나라 환경에 최적화된 기능을 탑재하고, <br />
        사용에 편리한 공간데이터 편집, 분석 등을 <br className="mb" /> 구축하는
        전문 GIS Solution
      </>
    ),

    engsub: (
      <>
        Specialized GIS solution equipped to build up <br className="pc" />{" "}
        <br className="tablet" />
        with optimized functions for editing and <br />
        analyzing geospatial data easily
      </>
    ),
  },
];
