import React from "react";

import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const BusinessFMS = ({ userAgent, lang }: Props) => {
  return (
    <main className="BusinessFMS">
      <div className="main-section">
        <div className="title-section">
          {lang === "kor" ? (
            <>
              현장업무 생산성을 높이는 <br />
              효율적인 업무툴 FMS
            </>
          ) : (
            <>
              Efficient work tool FMS to increase <br /> productivity in field
              work
            </>
          )}
        </div>

        <div className="content-section">
          {box.map((item, index) => {
            return (
              <div key={index} className="box">
                <div className="title">
                  {lang === "kor" ? item.title : item.eng}
                </div>
                <div className="sub">
                  {lang === "kor" ? item.sub : item.engsub}
                </div>
                <div className="img">
                  <img src={item.img} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default BusinessFMS;

const box = [
  {
    title: "시스템 판매",
    eng: "System Sales",
    sub: (
      <>
        <div className="dot"></div>
        <div>
          지리정보 기반으로 현장업무를 지원하는 <br />
          APP으로 다양한 현장에 적용해 현장 업무의 <br />
          생산성 향상이 가능
        </div>
      </>
    ),
    engsub: (
      <>
        <div className="dot"></div>
        <div className="div">
          We provide an APP based on geographic <br /> information to support
          fieldwork and improve <br /> productivity
        </div>
      </>
    ),

    img: "/assets/business/gis/pc/gis-icon1.svg",
  },

  {
    title: "시스템 교육 및 AS",
    eng: "System Training & After-sales Service",
    sub: (
      <>
        <div className="dot"></div>

        <div>
          고객에 맞춤형 지리정보 시스템을 구축하여 <br />
          최적의 솔루션 및 서비스를 제공합니다.
        </div>
      </>
    ),
    engsub: (
      <>
        <div className="dot"></div>
        <div className="div">
          Training systems and follow-up support are provided for users so that
          they can easily and efficiently operate the system
        </div>
      </>
    ),
    img: "/assets/business/gis/pc/gis-icon2.svg",
  },

  {
    title: "업무 단위 시스템 구축",
    eng: "Customized System",
    sub: (
      <>
        <div className="dot"></div>
        <div>
          고객에 맞춤형 지리정보 시스템을 구축하여 <br />
          최적의 솔루션 및 서비스를 제공합니다.
        </div>
      </>
    ),
    engsub: (
      <>
        <div className="dot"></div>
        <div className="div">
          We provide the optimal solution for clients by establishing a
          customized GIS system
        </div>
      </>
    ),
    img: "/assets/business/gis/pc/gis-icon3.svg",
  },

  {
    title: "월 정책 (민간시장 적용)",
    eng: "Monthly Pricing for Private Entities",
    sub: (
      <>
        <div className="dot"></div>
        <div>
          민간시장의 사용자들은 위해 금액부담이 큰 일회성
          <br /> 구매 대신 월별 요금이나 연간 요금을 지불하여 <br />
          사용이 가능합니다.
        </div>
      </>
    ),
    engsub: (
      <>
        <div className="dot"></div>
        <div className="div">
          We offer flexible payment options, including monthly and annual
          payments, to our private sector clients
        </div>
      </>
    ),
    img: "/assets/business/gis/pc/gis-icon5.svg",
  },
];
