import React from "react";
import { UserAgentType } from "../../../../common/common.inerface";
import "./css/index.css";
type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const AwardList = ({ userAgent, lang }: Props) => {
  return (
    <main className="AwardList">
      <div className="main-section">
        <div className="gradation-section"></div>
        <div className="title-section">
          <div className="title">
            {lang === "kor" ? (
              <>
                {" "}
                IGIS는 다양한 분야에서 <br />
                가능성과 능력을 인증 받았습니다
              </>
            ) : (
              <>
                Discover our award-winning
                <br className="pc" /> potential and capability
              </>
            )}
          </div>
        </div>

        <div className="img-section">
          {lang === "kor" ? (
            <>
              {" "}
              {AwardImg.map((item, index) => {
                return (
                  <div key={index} className="content">
                    <div className="list-img">
                      <img src={item.src} alt="img" srcSet={item.srcset} />
                    </div>

                    {item.title}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {" "}
              {AwardEng.map((item, index) => {
                return (
                  <div key={index} className="content">
                    <div className="list-img">
                      <img src={item.src} alt="img" srcSet={item.srcset} />
                    </div>

                    {item.engtitle}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default AwardList;

const AwardImg = [
  {
    src: "/assets/company/companyaward/awardlist/pc/list1.png",
    title: (
      <>221217 대구경북지방중소벤처기업청 표창장</>
    ),
    engtitle: "Daegu Gyeongbuk Regional SMEs Administration Award 2022",
    srcset:
      "/assets/company/companyaward/awardlist/pc/list1@2x.png 2x, /assets/company/companyaward/awardlist/pc/list1@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardlist/pc/list2.png",
    title: "221230_대구광역시장 표창장",
    engtitle: "Daegu Mayoral Commendation 2022 ",
    srcset:
      "/assets/company/companyaward/awardlist/pc/list2@2x.png 2x, /assets/company/companyaward/awardlist/pc/list2@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list4.png",
    title: "대구시장상_표창장_이후동",
    engtitle: "Daegu Mayoral Commendation 2020",
    srcset:
      "/assets/company/companyaward/awardlist/pc/list4@2x.png 2x, /assets/company/companyaward/awardlist/pc/list4@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardlist/pc/list5.png",
    title: (
      <>
        정보통신산업진흥원 감사패 <br className="pc" />
        2020년 지역SW사업 통합성과보고회 <br className="pc" />
        우수사례_(주)아이지아이에스
      </>
    ),
    engtitle: (
      <>
        Appreciation Plaque for Outstanding <br />
        Examples of Local Software Business Achievement 2020
      </>
    ),
    srcset:
      "/assets/company/companyaward/awardlist/pc/list5@2x.png 2x, /assets/company/companyaward/awardlist/pc/list5@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardlist/pc/list6.png",
    title: "정보통신산업진흥원_표창장",
    engtitle: <>NIPA Award 2018</>,
    srcset:
      "/assets/company/companyaward/awardlist/pc/list6@2x.png 2x, /assets/company/companyaward/awardlist/pc/list6@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list7.png",
    title: "중소기업인상_표창장_이호동",
    engtitle: <>Korea Federation of SMEs Award 2020</>,
    srcset:
      "/assets/company/companyaward/awardlist/pc/list7@2x.png 2x, /assets/company/companyaward/awardlist/pc/list7@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list8.png",
    title: "표창장_(주)아이지아이에스",
    engtitle: (
      <>
        AFoCO <br className="mb" /> Letter of Appreciation
      </>
    ),
    srcset:
      "/assets/company/companyaward/awardlist/pc/list8@2x.png 2x, /assets/company/companyaward/awardlist/pc/list8@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list9.png",
    title: (
      <>
        표창장(제2021-0900호) <br className="pc" />
        아이지아이에스(이호동)_2021.06.07
      </>
    ),
    engtitle: (
      <>
        AFoCO <br className="mb" /> Letter of Appreciation
      </>
    ),
    srcset:
      "/assets/company/companyaward/awardlist/pc/list9@2x.png 2x, /assets/company/companyaward/awardlist/pc/list9@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list10.png",
    title: "혁신벤처기업상_이호동",
    engtitle: (
      <>
        AFoCO <br className="mb" /> Letter of Appreciation
      </>
    ),
    srcset:
      "/assets/company/companyaward/awardlist/pc/list10@2x.png 2x, /assets/company/companyaward/awardlist/pc/list10@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list11.png",
    title: (
      <>
        AFOCO(아세아산림협력기구) 감사패 <br className="pc" />
        _(주)아이지아이에스
      </>
    ),
    engtitle: (
      <>
        AFoCO <br className="mb" /> Letter of Appreciation
      </>
    ),
    srcset:
      "/assets/company/companyaward/awardlist/pc/list11@2x.png 2x, /assets/company/companyaward/awardlist/pc/list11@3x.png 3x",
  },
];

const AwardEng = [
  {
    src: "/assets/company/companyaward/awardlist/pc/list1.png",
    title: (
      <>221217 대구경북지방중소벤처기업청 표창장</>
    ),
    engtitle: "Daegu Gyeongbuk Regional SMEs Administration Award 2022",
    srcset:
      "/assets/company/companyaward/awardlist/pc/list1@2x.png 2x, /assets/company/companyaward/awardlist/pc/list1@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardlist/pc/list2.png",
    title: "221230_대구광역시장 표창장",
    engtitle: "Daegu Mayoral Commendation 2022",
    srcset:
      "/assets/company/companyaward/awardlist/pc/list2@2x.png 2x, /assets/company/companyaward/awardlist/pc/list2@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list4.png",
    title: "대구시장상_표창장_이후동",
    engtitle: "Daegu Mayoral Commendation 2020",
    srcset:
      "/assets/company/companyaward/awardlist/pc/list4@2x.png 2x, /assets/company/companyaward/awardlist/pc/list4@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardlist/pc/list5.png",
    title: (
      <>
        정보통신산업진흥원 감사패 <br />
        2020년 지역SW사업 통합성과보고회 <br />
        우수사례_(주)아이지아이에스
      </>
    ),
    engtitle:
      "Appreciation Plaque for Outstanding Examples of Local Software Business Achievement 2020",
    srcset:
      "/assets/company/companyaward/awardlist/pc/list5@2x.png 2x, /assets/company/companyaward/awardlist/pc/list5@3x.png 3x",
  },
  {
    src: "/assets/company/companyaward/awardlist/pc/list6.png",
    title: "정보통신산업진흥원_표창장",
    engtitle: <>NIPA Award 2018</>,
    srcset:
      "/assets/company/companyaward/awardlist/pc/list6@2x.png 2x, /assets/company/companyaward/awardlist/pc/list6@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list7.png",
    title: "중소기업인상_표창장_이호동",
    engtitle: <>Korea Federation of SMEs Award 2020</>,
    srcset:
      "/assets/company/companyaward/awardlist/pc/list7@2x.png 2x, /assets/company/companyaward/awardlist/pc/list7@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list8.png",
    title: "표창장_(주)아이지아이에스",
    engtitle: <>NIPA Award 2018</>,
    srcset:
      "/assets/company/companyaward/awardlist/pc/list8@2x.png 2x, /assets/company/companyaward/awardlist/pc/list8@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list9.png",
    title: (
      <>
        표창장(제2021-0900호) <br />
        아이지아이에스(이호동)_2021.06.07
      </>
    ),
    engtitle: <>Korea Federation of SMEs Award 2021</>,
    srcset:
      "/assets/company/companyaward/awardlist/pc/list9@2x.png 2x, /assets/company/companyaward/awardlist/pc/list9@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list10.png",
    title: "혁신벤처기업상_이호동",
    engtitle: <>Innovation Venture Enterprise Award 2020</>,
    srcset:
      "/assets/company/companyaward/awardlist/pc/list10@2x.png 2x, /assets/company/companyaward/awardlist/pc/list10@3x.png 3x",
  },

  {
    src: "/assets/company/companyaward/awardlist/pc/list11.png",
    title: (
      <>
        AFOCO(아세아산림협력기구) 감사패 <br />
        _(주)아이지아이에스
      </>
    ),
    engtitle: <>AFoCO Letter of Appreciation</>,
    srcset:
      "/assets/company/companyaward/awardlist/pc/list11@2x.png 2x, /assets/company/companyaward/awardlist/pc/list11@3x.png 3x",
  },
];
