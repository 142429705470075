import "./css/index.css";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  RecruitInterface,
  RecrultmentInterface,
  UserAgentType,
} from "../../common/common.inerface";
import { formatDate } from "../../lib/lib";
import { StateChange } from "../../lib/lib";
type Props = {
  userAgent: UserAgentType;
  data: RecruitInterface[];
};

const Recrultment = ({ userAgent, data }: Props) => {
  const navigate = useNavigate();

  const __nav = useCallback(
    (id: any) => {
      navigate(`/recrultment/detail/${id}`);
    },
    [navigate]
  );

  return (
    <main className="Recrultment">
      <div className="title-section">
        <div className="left">
          <div className="title">RECRUITMENT</div>
          <div className="subtitle">채용</div>
        </div>

        <div className="right">
          <div className="gif">
            <video autoPlay loop muted playsInline preload="auto">
              <source src="/video/recrultment.webm" type="video/webm" />
              <source src="/video/recrultment.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className="main-section">
        <div className="title-wrapper">
          <div className="title">채용공고</div>
          <div className="sub">
            IGIS는 내일을 함께 할<br /> 팀원을 찾습니다
          </div>
        </div>

        <div className="content-section">
          {data.map((item, index) => {
            const cardState = StateChange({
              start: item.period.finish,
              finish: item.period.finish,
            });
            return (
              <div
                key={index}
                className={`box ${cardState.type}-box`}
                onClick={() => {
                  __nav(item.id);
                }}
              >
                <div className={`content `}>
                  <div className="content-wrapper">
                    <div className="title">{getTitle(item.category)}</div>
                    <div className={`active `}>{cardState.text}</div>
                  </div>
                </div>

                <div className="text-wrapper">
                  <div className="text">모집기간</div>
                  <div className="text subtext">
                    {formatDate(item.period.start, "-")} ~{" "}
                    {formatDate(item.period.finish, "-")}
                  </div>
                </div>

                <div className="text-wrapper">
                  <div className="text">조회수</div>
                  <div className="text subtext">{item.view}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default Recrultment;

const getTitle = (category: number) => {
  let imgUrl = "";
  switch (category) {
    case 0:
      imgUrl = "/assets/recruit/plan.svg";
      break;
    case 1:
      imgUrl = "/assets/recruit/development.svg";
      break;

    case 2:
      imgUrl = "/assets/recruit/dron.svg";
      break;

    case 3:
      imgUrl = "/assets/recruit/design.svg";
      break;

    case 4:
      imgUrl = "/assets/recruit/marketing.svg";
      break;

    case 5:
      imgUrl = "/assets/recruit/administrator.svg";
      break;

    case 6:
      imgUrl = "/assets/recruit/sales.svg";
      break;

    default:
      imgUrl = "/assets/recruit/sales.svg";
      break;
  }
  return (
    <>
      {recruitList[category]} <br />
      담당자 모집
      <div className="img">
        <img src={imgUrl} alt="이미지" />
      </div>
    </>
  );
};

export const recruitList = [
  "기획",
  "개발",
  "드론 측량",
  "디자인",
  "해외 마케팅",
  "총무(인사)",
  "영업",
];
