import { ContactInterface } from "../interface/list/list.interface";

export interface ContactInter extends ContactInterface {
  content: string;
  fileData: { name: string; url: string }[];
}

export function ContactReducer(
  state: ContactInter,
  { payload, type, index }: { payload: any; type: string; index?: number }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "company":
      return { ...state, company: payload };
    case "manager":
      return { ...state, manager: payload };
    case "phone":
      return { ...state, phone: payload };
    case "email":
      return { ...state, email: payload };
    case "agree":
      return { ...state, isAgree: payload };
    case "rank":
      return { ...state, rank: payload };

    case "use":
      return { ...state, use: payload };

    case "text":
      return { ...state, text: payload };

    case "selectedButton":
      return { ...state, selectedButton: payload };

    case "selectedProductButton":
      return { ...state, selectedProductButton: payload };

    case "reset":
      return {
        manager: "",
        company: "",
        phone: "",
        email: "",
        rank: "",
        selectedButton: null,
        isAgree: false,
        selectedProductButton: null,
        text: "",
        use: "",
        fileData: [],
      };

    case "fileData":
      if (index !== undefined) {
        let arr = state.fileData;
        arr[index] = payload;
        return { ...state, fileData: arr };
      } else {
        let arr = state.fileData;
        arr[0] = payload;
        return { ...state, fileData: arr };
      }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
