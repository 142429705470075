import React, { useCallback, useEffect, useState } from "react";
import "./css/index.css";
import { Link, useLocation } from "react-router-dom";
import { UserAgentType } from "../../common/common.inerface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducer";
import { LangChange } from "../../reducer/config";

type Props = {
  userAgent: UserAgentType;
};

const Header = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [HeaderState, setHeaderState] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [ischangeLanguage, setIsChangeLanguage] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsChangeLanguage((prevState) => !prevState);
  }, []);

  const changeLanguage = useCallback(() => {
    dispatch(LangChange(lang === "kor" ? "eng" : "kor"));
    setIsChangeLanguage(false);
  }, [dispatch, lang]);

  const handleNavItemClick = useCallback((index: any) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? -1 : index));
  }, []);

  const handleButtonClick = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  useEffect(() => {
    const root = document.getElementById("root");
    function ScrollEvent(e: Event) {
      const target = e.target as HTMLElement;
      const top = target.scrollTop;
      if (top > 100) {
        setHeaderState("black");
      } else {
        setHeaderState("");
      }
    }
    root?.addEventListener("scroll", ScrollEvent);

    return () => {
      root?.removeEventListener("scroll", ScrollEvent);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
    return () => {};
  }, [location]);

  return (
    <header className={HeaderState}>
      {isOpen ? (
        <div className="mobile-nav-wrapper">
          <ul className="mobile-nav-list">
            {navItems.map((navItem, index) => (
              <li key={index} className="mobile-nav-item">
                <button
                  className="mobile-nav-button"
                  onClick={() => handleNavItemClick(index)}
                >
                  {lang === "kor" ? navItem.title : navItem.engtitle}
                  {openMenuIndex === index ? (
                    <i className="nav nav-chevron-up"></i>
                  ) : (
                    <i className="nav nav-chevron-down"></i>
                  )}
                </button>
                {openMenuIndex === index && (
                  <ul className="mobile-sub-nav-list">
                    {lang === "kor"
                      ? navItem.mbItems.map((mbItems, index) => (
                          <li key={index} className="mobile-sub-nav-item">
                            <Link to={mbItems.url}>{mbItems.label}</Link>
                          </li>
                        ))
                      : navItem.mbEngItems.map((mbEngItems, index) => (
                          <li key={index} className="mobile-sub-nav-item">
                            <Link to={mbEngItems.url}>{mbEngItems.label}</Link>
                          </li>
                        ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <button>
            <img
              src="/assets/common/close.svg"
              alt="닫기"
              className="close"
              onClick={handleButtonClick}
            />
          </button>
        </div>
      ) : (
        <div className="wrapper">
          <div className="left">
            <Link to="/" className="logo-link">
              <img src="/assets/header/logo.svg" alt="igis" />
            </Link>

            {userAgent === "pc" && (
              <>
                <div className="pc-nav-wrapper">
                  {navItems.map((navItem, index) => (
                    <ul
                      key={index}
                      className={`drop-menu ${
                        lang === "kor" ? navItem.type : "eng-drop-menu"
                      }`}
                    >
                      <div className="title-menu">
                        {lang === "kor" ? (
                          <>
                            {navItem.titleItems.map((titleItems, index) => (
                              <li key={index}>
                                <Link to={titleItems.url}>
                                  {titleItems.label}
                                </Link>
                              </li>
                            ))}
                          </>
                        ) : (
                          <>
                            {navItem.engtitleItems.map(
                              (engtitleItems, index) => (
                                <li key={index}>
                                  <Link to={engtitleItems.url}>
                                    {engtitleItems.label}
                                  </Link>
                                </li>
                              )
                            )}
                          </>
                        )}
                      </div>

                      <div className="sub-menu">
                        {lang === "kor" ? (
                          <>
                            {navItem.subItems.map((subItem, index) => (
                              <li key={index}>
                                <Link to={subItem.url}>{subItem.label}</Link>
                              </li>
                            ))}
                          </>
                        ) : (
                          <>
                            {navItem.engItems.map((engItems, index) => (
                              <li key={index}>
                                <Link to={engItems.url}>{engItems.label}</Link>
                              </li>
                            ))}
                          </>
                        )}
                      </div>
                    </ul>
                  ))}
                  <div className="drop"></div>
                </div>
              </>
            )}
          </div>

          {userAgent === "pc" ? (
            <div className="right">
              <button className="shop-btn" disabled={true}>
                <span />
                <div>
                  {lang === "kor" ? "디포스 플랫폼 바로가기" : "DFOS shop"}
                </div>
              </button>
              {/* <div className="shop">
                  <a href="/" className="shop-link">
                    <img
                      src={`/assets/header/shop${
                        lang === "eng" ? "-eng" : ""
                      }.svg`}
                      alt="igis"
                    />
                  </a>
                </div> */}
              <div
                className={`dropdown-menu ${ischangeLanguage && "dropdown"}`}
              >
                <button className="language" onClick={toggleMenu}>
                  <img
                    src={`/assets/header/${
                      lang === "kor" ? "korea" : "usa"
                    }.svg`}
                    alt={lang}
                  />
                  <div className="arrow">
                    <img
                      src="/assets/header/arrow.svg"
                      alt=""
                      className={ischangeLanguage ? "arrow-rotated" : ""}
                    />
                  </div>
                </button>
                {ischangeLanguage && (
                  <ul className="menu-items">
                    <li onClick={changeLanguage}>
                      <img
                        src={`/assets/header/${
                          lang === "kor" ? "usa" : "korea"
                        }.svg`}
                        alt={lang === "kor" ? "eng" : "kor"}
                      />
                    </li>
                  </ul>
                )}
              </div>
            </div>
          ) : (
            <div className="right">
              <div
                className={`dropdown-menu ${ischangeLanguage && "dropdown"}`}
              >
                <button className="language" onClick={toggleMenu}>
                  <img
                    src={`/assets/header/${
                      lang === "kor" ? "korea" : "usa"
                    }.svg`}
                    alt={lang}
                  />
                  <div className="arrow">
                    <img
                      src="/assets/header/arrow.svg"
                      alt=""
                      className={ischangeLanguage ? "arrow-rotated" : ""}
                    />
                  </div>
                </button>
                {ischangeLanguage && (
                  <ul className="menu-items">
                    <li onClick={changeLanguage}>
                      <img
                        src={`/assets/header/${
                          lang === "kor" ? "usa" : "korea"
                        }.svg`}
                        alt={lang === "kor" ? "eng" : "kor"}
                      />
                    </li>
                  </ul>
                )}
              </div>
              <button onClick={handleButtonClick}>
                <img src="/assets/header/menu.svg" alt="igis" />{" "}
              </button>
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;

const navItems = [
  {
    title: "기업소개",

    titleItems: [{ label: "기업 소개", url: "/company" }],
    engtitleItems: [{ label: "COMPANY", url: "/company" }],
    engtitle: "COMPANY",
    subItems: [
      { label: "기업 소개", url: "/company" },
      { label: "인증 및 수상", url: "/award" },
      { label: "뉴스 및 홍보 영상", url: "/news" },
    ],
    engItems: [
      { label: "COMPANY", url: "/company" },
      { label: "AWARD", url: "/award" },
      { label: "NEWS & MEDIA", url: "/news" },
    ],

    mbItems: [
      { label: "기업 소개", url: "/company" },
      { label: "인증 및 수상", url: "/award" },
      { label: "뉴스 및 홍보 영상", url: "/news" },
    ],

    mbEngItems: [
      { label: "COMPANY", url: "/company" },
      { label: "AWARD", url: "/award" },
      { label: "NEWS & MEDIA", url: "/news" },
    ],
  },
  {
    title: "사업분야",
    engtitleItems: [{ label: "BUSINESS", url: "/business" }],
    titleItems: [{ label: "사업분야", url: "/business" }],
    engtitle: "BUSINESS",
    subItems: [
      { label: "드론", url: "/business/dron" },
      { label: "GIS 시스템", url: "/business" },
      { label: "FMS", url: "/business/fms" },
      { label: "R&D 및 용역", url: "/business/service" },
    ],

    mbItems: [
      { label: "드론", url: "/business/dron" },
      { label: "GIS 시스템", url: "/business" },
      { label: "FMS", url: "/business/fms" },
      { label: "R&D 및 용역", url: "/business/service" },
    ],

    engItems: [
      { label: "DRONE", url: "/business/dron" },
      { label: "GIS System", url: "/business" },
      { label: "FMS", url: "/business/fms" },
      { label: "R&D Service", url: "/business/service" },
    ],

    mbEngItems: [
      { label: "DRONE", url: "/business/dron" },
      { label: "GIS System", url: "/business" },
      { label: "FMS", url: "/business/fms" },
      { label: "R&D Service", url: "/business/service" },
    ],
  },
  {
    title: "솔루션",
    engtitleItems: [{ label: "SOLUTION", url: "/solution/gis" }],
    titleItems: [{ label: "솔루션", url: "/solution/gis" }],
    engtitle: "SOLUTION",
    subItems: [
      { label: "드론 솔루션", url: "/solution/dron" },
      { label: "GIS 솔루션", url: "/solution/gis" },
    ],

    mbItems: [
      { label: "드론 솔루션", url: "/solution/dron" },
      { label: "GIS 솔루션", url: "/solution/gis" },
    ],
    mbEngItems: [
      { label: "DRONE SOLUTION", url: "/solution/dron" },
      { label: "GIS SOLUTION", url: "/solution/gis" },
    ],

    engItems: [
      { label: "DRONE SOLUTION", url: "/solution/dron" },
      { label: "GIS SOLUTION", url: "/solution/gis" },
    ],

    type: "text2",
  },
  {
    title: "문의",
    engtitleItems: [{ label: "CONTACT", url: "/contactus" }],
    titleItems: [{ label: "문의", url: "/contactus" }],
    engtitle: "CONTACT",
    subItems: [],
    engItems: [],
    type: "text",
    mbItems: [{ label: "문의", url: "/contactus" }],
    mbEngItems: [{ label: "CONTACT", url: "/contactus" }],
  },
  {
    title: "채용",
    engtitleItems: [],
    titleItems: [{ label: "채용", url: "/recrultment" }],
    engtitle: "",
    subItems: [],
    engItems: [],
    type: "text",
    mbItems: [{ label: "채용", url: "/recrultment" }],
    mbEngItems: [],
  },
];
