import React from "react";

import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const BusinessService = ({ userAgent, lang }: Props) => {
  return (
    <main className="BusinessService">
      <div className="main-section">
        <div className="title-section">
          {lang === "kor" ? (
            <>
              {" "}
              끊임없는 연구개발 <br />
              R&D 및 용역 분야
            </>
          ) : (
            <>
              Continuous R&D for
              <br /> innovation and improvement
            </>
          )}
        </div>

        <div className="content-section">
          {box.map((item, index) => {
            return (
              <div key={index} className="box">
                <div className="title">
                  {" "}
                  {lang === "kor" ? item.title : item.engtitle}
                </div>
                <div className="sub">
                  {" "}
                  {lang === "kor" ? item.sub : item.engsub}
                </div>
                <div className="subtitle sub">
                  {" "}
                  {lang === "kor" ? item.subtitle : item.engsubtitle}
                </div>
                <div className="img">
                  <img src={item.img} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default BusinessService;
const box = [
  {
    title: "과제사업",
    engtitle: <>Government-initiated Projects</>,

    sub: [
      <div>
        <div className="dot"></div>
        R&D 과제 사업 / 비 R&D 과제 사업
      </div>,
    ],

    engsub: [
      <>
        <div className="dot"></div>
        <div>R&D Projects / Non-R&D Projects</div>,
      </>,
    ],
    subtitle: [
      <>
        <div className="dot"></div>
        <div>
          기업의 역량 강화와 매출신장을 위해 다양한 <br />
          과제사업을 진행
        </div>
      </>,
    ],

    engsubtitle: [
      <>
        <div className="dot"></div>
        <div className="div">
          We perform various government-sponsored projects to strengthen the
          company's capabilities and increase revenue
        </div>
      </>,
    ],

    img: "/assets/business/service/icon1.svg",
  },

  {
    title: "용역 및 제안사업",
    engtitle: <>Business Proposals and Services</>,

    sub: [
      <>
        <div className="dot"></div>

        <div>
          보유 중인 기술과 솔루션을 용역 및 제안 사업에 <br />
          적용하여 고객의 전문성과 역량 강화를 제시
        </div>
      </>,
    ],
    engsub: [
      <>
        <div className="dot"></div>
        <div className="div">
          We apply our technology and solutions to service and business
          proposals, providing customers with enhanced expertise and competence
        </div>
      </>,
    ],

    img: "/assets/business/service/icon3.svg",
  },
];
