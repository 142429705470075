import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../common/common.inerface";
import Solution from "../../../../components/Solution/Solution";

type Props = {
  userAgent: UserAgentType;
  lang: "kor" | "eng";
};
const Streaming = ({ userAgent, lang }: Props) => {
  return (
    <main className="Streaming">
      <Solution
        lang={lang}
        gradationType=""
        box_titles={box_titles}
        gradation={false}
        type="streaming"
        box_layouts={box_layouts}
        userAgent={userAgent}
        src="/assets/solution/dron/streaming/pc/streaming-img.png"
        srcSet="/assets/solution/dron/streaming/pc/streaming-img@2x.png 2x, /assets/solution/dron/streaming/pc/streaming-img@3x.png 3x"
      />
    </main>
  );
};

export default Streaming;

const box_layouts = [
  {
    point: "Point 01",
    title: <>스트리밍 재생</>,
    engtitle: <>Streaming</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>선택된 드론 개수에 따라 자동 레이아웃 설정</div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>
          {" "}
          Auto layout setting depending on the <br />
          number of selected drones
        </div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div> Bitrate setting depending on the number of selected drones</div>
      </>
    ),

    engsub2: (
      <>
        <div className="dot"></div>
        <div>Full streaming screen provided</div>
      </>
    ),

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        선택된 드론 개수에 따라 화질(bitrate) 설정
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>전체 스트리밍 화면 제공
      </>
    ),

    src: "/assets/solution/dron/streaming/pc/streaming-boximg1.png",
    srcSet:
      "/assets/solution/dron/streaming/pc/streaming-boximg1@2x.png 2x, /assets/solution/dron/streaming/pc/streaming-boximg1@3x.png 3x",
  },

  {
    point: "Point 02",
    title: <>드론 정보 및 드론 리스트 표시</>,
    engtitle: <>Display of Drone Info and List</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>드론 이름 및 정보 표시</div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>Drone name and info</div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div>Drone flight altitude (m)</div>
      </>
    ),

    engsub2: (
      <>
        <div className="dot"></div>
        <div>Drone location (address info)</div>
      </>
    ),

    engsub3: (
      <>
        <div className="dot"></div>
        <div>Display of distance to the home point (m)</div>
      </>
    ),

    engsub4: (
      <>
        <div className="dot"></div>
        <div>Flight speed (m/s)</div>
      </>
    ),

    engsub5: (
      <>
        <div className="dot"></div>
        <div>Battery status</div>
      </>
    ),
    engsub6: (
      <>
        <div className="dot"></div>
        <div>Connection status (connected / disconnected)</div>
      </>
    ),

    sub1: (
      <>
        <div className="dot"></div>
        <div>드론 비행고도 표시(m)</div>
      </>
    ),
    sub2: (
      <>
        <div className="dot"></div>
        <div>드론 위치정보(주소정보)</div>
      </>
    ),
    sub3: (
      <>
        <div className="dot"></div>
        <div>홈포인트까지 거리 표시(m)</div>
      </>
    ),
    sub4: (
      <>
        <div className="dot"></div>
        <div>드론 비행속도 표시(m/s)</div>
      </>
    ),
    sub5: (
      <>
        <div className="dot"></div>
        <div>배터리 상태 표시</div>
      </>
    ),
    sub6: (
      <>
        <div className="dot"></div>
        <div>연결 상태별 표시(연결, 미연결 드론 표시)</div>
      </>
    ),

    src: "/assets/solution/dron/streaming/pc/streaming-boximg2.png",
    srcSet:
      "/assets/solution/dron/streaming/pc/streaming-boximg2@2x.png 2x, /assets/solution/dron/streaming/pc/streaming-boximg2@3x.png 3x",
    type: "reverse",
  },

  {
    point: "Point 03",
    title: <>화면 분할 및 화질</>,
    engtitle: <>Screen Division and Quality</>,

    sub: (
      <>
        <div className="dot"></div>
        <div>1~2개 연결 (FHD(1080p) 화질 재생)</div>
      </>
    ),

    engsub: (
      <>
        <div className="dot"></div>
        <div>1-2 Videos: FHD 1080p</div>
      </>
    ),

    engsub1: (
      <>
        <div className="dot"></div>
        <div>3-4 Videos: HD 720p</div>
      </>
    ),
    engsub2: (
      <>
        <div className="dot"></div>
        <div>5-6 Videos: SD 360p</div>
      </>
    ),
    engsub3: (
      <>
        <div className="dot"></div>
        <div>7-9 Videos: 160p</div>
      </>
    ),

    sub1: (
      <>
        {" "}
        <div className="dot"></div>
        3~4개 연결(HD(720p) 화질 재생)
      </>
    ),

    sub2: (
      <>
        {" "}
        <div className="dot"></div>5~6개 연결(SD(360p) 화질 재생)
      </>
    ),

    sub3: (
      <>
        {" "}
        <div className="dot"></div>7~9개 연결 (160p 화질 재생)
      </>
    ),

    src: "/assets/solution/dron/streaming/pc/streaming-boximg3.png",
    srcSet:
      "/assets/solution/dron/streaming/pc/streaming-boximg3@2x.png 2x, /assets/solution/dron/streaming/pc/streaming-boximg3@3x.png 3x",
  },
];

const box_titles = [
  {
    title: (
      <>
        DFOS <br className="" />
        Streaming Viewer
      </>
    ),

    engtitle: (
      <>
        DFOS <br className="" />
        Streaming Viewer
      </>
    ),
    sub: (
      <>
        여러개의 동영상을 편리하게 <br className="mb" />
        재생할 수 있는 스트리밍 뷰어
      </>
    ),

    engsub: (
      <>
        Streaming viewer that can
        <br /> conveniently play multiple drone
        <br /> video sources
      </>
    ),
  },
];
