import React, { useCallback } from "react";
import "./css/index.css";
import Map from "../../components/map/map";
import { UserAgentType } from "../../common/common.inerface";
import { RootState } from "../../reducer";
import { useSelector } from "react-redux";
import Contact from "../../components/Contact/Contact";
import { ContactInter } from "../../lib/reducer";
type Props = {
  userAgent: UserAgentType;
};
const ContactWay = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  return (
    <main className="ContactU">
      <div className="adress-section">
        <div className="title">
          {lang === "kor" ? <>오시는길</> : <>LOCATIONS</>}
        </div>
        <div className="map">
          <Map />
        </div>
        <div className="subtitle">ADDRESS</div>
        <div className="sub">
          {lang === "kor" ? (
            <>
              {" "}
              본사 | 대구광역시 수성구 알파시티1로 232, 3층 (대흥동)
              <br /> 창원지사 | 경상남도 창원시 성산구 용지로169번길 11-31{" "}
              <br className="mb" /> 제3층(동진빌딩) (용호동)
            </>
          ) : (
            <>
              Head Office | 3F, 232, Alphacity 1-ro,
              <br /> Suseong-gu, Daegu, Republic of Korea <br />
              <br />
              Changwon Branch | 11-31, Yongji-ro <br /> 169beon-gil,
              Seongsan-gu, Changwon-si,
              <br /> Gyeongsangnam-do, Republic of Korea
            </>
          )}
        </div>
      </div>

      <div className="line">
        <img src={`/assets/contactus/${userAgent}/line.svg`} alt="" />
      </div>

      <div className="communication-section">
        {communication.map((item, idx) => {
          return (
            <div key={idx} className="box">
              <div className="text">{item.Text}</div>
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default ContactWay;

const communication = [
  {
    Text: (
      <>
        PHONE <br />
        <span>070-8740-5534</span>
      </>
    ),
  },
  {
    Text: (
      <>
        FAX <br />
        <span>070-8740-1730</span>
      </>
    ),
  },

  {
    Text: (
      <>
        E-mail <br />
        <span>ceo@igis.co.kr</span>
      </>
    ),
  },
];
