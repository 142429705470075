import { Route, Routes } from "react-router-dom";
import TopNavSection from "../../components/TopNavSection/TopNavSection";
import { UserAgentType } from "../../common/common.inerface";

import "./css/index.css";
import Panorama from "../../page/Solution/DronSolution/Panorama/Panorama";
import Pilot from "../../page/Solution/DronSolution/Pilot/Pilot";
import Viewer from "../../page/Solution/DronSolution/Viewer/Viewer";
import Streaming from "../../page/Solution/DronSolution/Streaming/Streaming";
import ControlSystem from "../../page/Solution/DronSolution/Control System/ControlSystem";
import { RootState } from "../../reducer";
import { useSelector } from "react-redux";
import Ims from "../../page/Solution/DronSolution/Ims/Ims";

type Props = {
  userAgent: UserAgentType;
};

const DronSolutionRoute = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, "kor" | "eng">(
    (state) => state.config.lang
  );
  return (
    <main className="solution-page">
      <div className="title-section">
        <div className="left">
          <div className="title">DRONE SOLUTION</div>
          {lang === "kor" && <div className="subtitle">드론 솔루션</div>}
        </div>

        <div className="right">
          <div className="gif">
            <video autoPlay loop muted playsInline preload="auto">
              <source src="/video/solution.webm" type="video/webm" />
              <source src="/video/solution.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <TopNavSection
        nav_layouts={nav_layouts}
        page_type="dron"
        userAgent={userAgent}
      />
      <Routes>
        <Route
          path="/panorama"
          element={<Panorama userAgent={userAgent} lang={lang} />}
        />
        <Route path="/" element={<Pilot userAgent={userAgent} lang={lang} />} />
        <Route
          path="viewer"
          element={<Viewer userAgent={userAgent} lang={lang} />}
        />
        <Route
          path="streaming"
          element={<Streaming userAgent={userAgent} lang={lang} />}
        />
        <Route
          path="ControlSystem"
          element={<ControlSystem userAgent={userAgent} lang={lang} />}
        />

        <Route path="ims" element={<Ims userAgent={userAgent} lang={lang} />} />
      </Routes>
    </main>
  );
};

export default DronSolutionRoute;

const nav_layouts = [
  {
    title: "Pilot",
    link: "/solution/dron",
    eng: "Pilot",
  },
  {
    title: "Panorama",
    link: "/solution/dron/panorama",
    eng: "Panorama",
  },

  {
    title: "IMS",
    link: "/solution/dron/ims",
    eng: "IMS",
  },

  {
    title: "Viewer",
    link: "/solution/dron/viewer",
    eng: "Viewer",
  },

  {
    title: "ControlSystem",
    link: "/solution/dron/controlsystem",
    eng: "ControlSystem",
  },
  {
    title: "Streaming",
    link: "/solution/dron/streaming",
    eng: "Streaming",
  },
];
